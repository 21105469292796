import { Custobtn } from "./Custobtn";
import "./application.css";
import { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { SiStartrek } from "react-icons/si"; 
import Lottie from 'lottie-react';
import applications from '../../Component/FILEJSON/other pages and includes/applications.json'
import companymanagement from '../../Component/FILEJSON/applications/company management system.json'
import ecommerce from '../../Component/FILEJSON/applications/ecommerce.json'
import livechat from '../../Component/FILEJSON/applications/livechat.json'
import pos from '../../Component/FILEJSON/applications/POS.json'
import marketplace from '../../Component/FILEJSON/applications/marketplace.json'
import recruiting from '../../Component/FILEJSON/applications/recruiting portal.json'
import screenshare from '../../Component/FILEJSON/applications/screenshare.json';
import servicebooking from '../../Component/FILEJSON/applications/service booking.json';
import websitemanagementsystem from '../../Component/FILEJSON/applications/website management system.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import getStarted from '../../Component/FILEJSON/other pages and includes/Getstarted.json';
import { GetStartedbtn } from "./Getstartedbtn";
import { FaBattleNet } from "react-icons/fa6";
import { MdKeyboardArrowLeft,MdOutlineKeyboardArrowRight } from "react-icons/md";
import {Helmet} from "react-helmet";
import Slider from 'react-slick';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const applicationdata=[{
  id:'01',
  title:'eCommerce System',
  slug:"eCommerce_System",
  para:"Our eCommerce system merges the flexibility of Bootstrap with the robustness of Laravel, delivering an intuitive and powerful platform for your online business.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:ecommerce,
  AltTitle:"Elevate Your Online Shop",
  AltDescription:'Discover our eCommerce system, a powerful fusion of Bootstrap and Laravel, tailored to enhance your online business.'

},
{
  id:'02',
  title:'Service Booking System',
  slug:"Service_Booking_System",
  para:"Efficiently manage appointments with our service booking system, built on the powerful combination of Bootstrap and Laravel for seamless operation.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:servicebooking,
  AltTitle:"Efficient Appointment Management",
  AltDescription:'Explore our service booking system, meticulously crafted with Bootstrap and Laravel for seamless appointment management.'
},
{
  id:'03',
  title:'Company Management System',
  slug:"Company_Management_System",
  para:"Streamline your company's internal processes with our company management system, meticulously crafted using Bootstrap and Laravel for optimal performance.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:companymanagement,
  AltTitle:"Optimize Internal Processes",
  AltDescription:"Enhance your company's efficiency with our management system, a perfect blend of Bootstrap and Laravel for streamlined operations."
},{
  id:'04',
  title:'Website Management System',
  slug:"Website_Management_System",
  para:"Take full control of your digital presence with our website management system, offering a perfect blend of Bootstrap and Laravel for superior online management.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:websitemanagementsystem,
  AltTitle:"Control Your Online Presence",
  AltDescription:'Take charge of your online presence with our website management system, integrating Bootstrap and Laravel for optimal control.'
},{
  id:'05',
  title:'POS System',
  slug:"POS_System",
  para:"Enhance your point-of-sale operations with our POS system, leveraging the strengths of Bootstrap and Laravel for a smooth and efficient user experience.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:pos,
  AltTitle:"Seamless Point-of-Sale Operations",
  AltDescription:'Optimize your point-of-sale operations with our POS system, expertly crafted with Bootstrap and Laravel for a seamless experience.'
},{
  id:'06',
  title:'Recruiting Portal',
  slug:"Recruiting_Portal",
  para:"Simplify and optimize your recruitment process with our recruiting portal, designed with Bootstrap and Laravel to ensure efficiency and effectiveness.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:recruiting,
  AltTitle:"Efficient Recruitment Processes",
  AltDescription:'Simplify your recruitment processes with our portal, developed using Bootstrap and Laravel for efficient hiring solutions.'
},{
  id:'07',
  title:'Marketplace',
  slug:"Marketplace",
  para:"Effortlessly create and manage your online marketplace with our dynamic solution, combining the power of Bootstrap and Laravel for a robust and adaptable platform.",
  frontendfield:"Bootstrap",
  backendfield:"Laravel",
  animationData:marketplace,
  AltTitle:"Manage Your Online Marketplace",
  AltDescription:'Effortlessly create and manage your online marketplace with our solution, a dynamic fusion of Bootstrap and Laravel.'
},{
  id:'08',
  title:'Live Chat, Video and Calling Web App',
  slug:"Live_Chat,_Video_and_Calling_Web_App",
  para:"Engage your audience in real-time with our live chat, video, and calling web app, powered by React.js and Livewire Laravel for an interactive and dynamic user experience.",
  frontendfield:"React.js + Livewire",
  backendfield:"Laravel",
  animationData:livechat,
  AltTitle:"Real-Time Engagement Platform",
  AltDescription:'Engage with your audience in real-time through our web app, powered by React.js and Livewire Laravel for dynamic user experiences.'
},
,{
  id:'09',
  title:'Screen Sharing with Live Chat and Audio',
  slug:"Screen_Sharing_with_Live_Chat_and_Audio",
  para:"Enable seamless collaboration with our cutting-edge solution for screen sharing, live chat, and audiovideo streaming, utilizing Node.js and Angular.js for advanced functionality.",
  frontendfield:"Angular.js",
  backendfield:"Node.js",
  animationData:screenshare,
  AltTitle:"Seamless Collaboration Hub",
  AltDescription:'Facilitate seamless collaboration with screen sharing, live chat, and audio-video streaming. Our solution leverages Node.js and Angular.js for cutting-edge functionality.'
}]

export const Application =()=>{
    const [zoomImage,setZoomImage]=useState(false);
    const [largepic,setlargepic]=useState({title:"",pic:""});
    const [isHover,setisHover]=useState(false);
    const [isback,setisback]=useState(false);

    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [hoveredIcon1, setHoveredIcon1] = useState(null);
  

    const handleMouseEnter = (id) => {
      setHoveredIcon(id);
    };
  
    const handleMouseLeave = () => {
      setHoveredIcon(null);
    };



  
    const showLargeImageHandler = (url) => {
      setZoomImage(true);
      setlargepic(url)
    };
    const hideLargeImageHandler = () => {
        setZoomImage(false);
        setlargepic('')
      };
      const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
      }));

      const sliderRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const setSlideHeights = () => {
    let tallestSlideHeight = 0;
    const $slides = $(sliderRef.current.innerSlider.list).find('.slick-slide');
    $slides.css('min-height', '');
    $slides.each(function () {
      const slideHeight = $(this).outerHeight();
      if (slideHeight > tallestSlideHeight) {
        tallestSlideHeight = slideHeight;
      }
    });
    $slides.css('min-height', tallestSlideHeight + 'px');
  };

  const settings = {
    fade: false,
    rtl: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3.5,
    arrows: false,
    dots: false,
    speed: 500,
    autoplay: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          infinite: false,
          slidesToScroll: 1,
          slidesToShow: 2,
          arrows: false,
          dots: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 599,
        settings: {
          infinite: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          swipe: true,
        },
      },
    ],
    beforeChange: (current, next) => {
      const calc = (next / (sliderRef.current.innerSlider.props.children.length - sliderRef.current.innerSlider.props.slidesToShow)) * 100;
      setProgress(calc);
      $('.platform-parts').removeClass('animate');
      $(`.slick-slide[data-index="${next}"] .platform-parts`).addClass('animate');
      $('video').each(function() {
        this.pause();
      });
      $(`.slick-slide[data-index="${next}"] video`).get(0)?.play();
    },
    afterChange: () => {
      setSlideHeights();
    },
  };

  useEffect(() => {
    if (sliderRef.current) {
      setSlideHeights();

      $(window).on('resize', () => {
        setSlideHeights();
      });

      return () => {
        $(window).off('resize');
      };
    }
  }, [sliderRef]);
    return(
        <div className="servicemargin">
            <Helmet>
        <title>Web Application Solutions | Ready-to-Use Web Applications and Customizable</title>
        <meta name="description" content="Web Application Solutions available from a wide range by an Award-Winning IT Company, DA Tech.
Explore our solutions which are ready-to-use web applications for seamless operations. Customization
possible to match your unique requirements." />
        <meta name="keywords" content="Web application solutions, ready-to-use web applications, customizable, award-winning IT company"/>
     </Helmet>
            <div className='row mx-2'>
            <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-11" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><h1 className="text-md-center subpagestitle mb-0 mt-2 d-flex justify-content-md-center justify-content-end mob-701 "><Lottie
      animationData={applications}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 40, height: 40 }}
      id="faqiconmain"
    /><p className="mt-1" style={{fontFamily:"CFNotche-Bold"}}>Web Application Solutions | Ready-to-Use Web Applications</p></h1></div>
            </div>
            <p className="text-center mt-1 mb-1 mobilepara mx-md-4" style={{fontFamily:"CFNotche-Bold"}}>Web Application Solutions available from a wide range by an Award-Winning IT Company, DA Tech. we
provide a comprehensive suite of Web Application Solutions designed to streamline and enhance your
business operations. Customization possible to match your unique requirements.</p>
            {/*<div className="swiper-container" style={{ height: '100vh' }}>
            <Swiper
        slidesPerView={3}
        centeredSlides={true}
        slidesPerGroupSkip={2}
        className="mySwiper application-silderhight"
        pagination={{ type: 'progressbar', el: '.swiper-pagination' }}
        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 40,
          }
        }}

      >
    {applicationdata && applicationdata.map(({id,title,para,Linklaunch,frontendfield,backendfield,animationData,AltTitle,AltDescription})=>(
        <SwiperSlide key={id} id="applicationpadding">
        <div className="applicationcontainer pb-1"   onMouseEnter={() => handleMouseEnter(id)}
                  onMouseLeave={() => handleMouseLeave(id)}>
          <div className="card-content">  
          <Link to={`/solutions/${id}`} style={{color:'#fff'}}><div className="applicationcontainerbox">
          <Lottie
      animationData={animationData}
      loop={hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      className="thumbnail-image"
      title={AltTitle}
      alt={AltDescription}
    />
                      
                  </div></Link>
                  <Link to={`/solutions/${id}`} style={{color:'#fff'}}> <h1>{title}</h1></Link>
                  <Link to={`/solutions/${id}`} style={{color:'#fff'}}><p>{para}</p></Link>
  <div className='row'>
  <div className="col-6">
  <p><b style={{color:'#a49f9f',textTransform:'uppercase'}}>Frontend : </b>{frontendfield}</p>
  </div>
  <div className="col-6">
  <p><b style={{color:'#a49f9f',textTransform:'uppercase'}}>Backend : </b>{backendfield}</p>
  </div>
  </div>
        <div className="row ggggg">
          
          <Link to={Linklaunch}><button className="launchbtn1">
          <div className="launchbtnwrapper2">
              <div className="gvzymi1"><svg id="smallcirll" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26.5" stroke="url(#paint0_angular)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 20 8 1 10 8"></circle><defs><radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.5 27.5) rotate(107.904) scale(34.1541)"><stop offset="0.0870822" stop-color="#000000"></stop><stop offset="0.69191" stop-color="#000000"></stop></radialGradient></defs></svg></div>
          </div>
          <div className="kRiXyW1">
          <Lottie
      animationData={launch}
      loop={hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      title={AltTitle}
      alt={AltDescription}
    />
          </div>
          <div className="cbypVx1">Launch</div></button></Link>
       
            
            <Link to="https://hrm.thedatech.com/client-signup">
              <button className="launchbtn1">
          <div className="launchbtnwrapper2">
              <div className="gvzymi1"><svg id="smallcirll" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26.5" stroke="url(#paint0_angular)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 20 8 1 10 8"></circle><defs><radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.5 27.5) rotate(107.904) scale(34.1541)"><stop offset="0.0870822" stop-color="#000000"></stop><stop offset="0.69191" stop-color="#E1467C"></stop></radialGradient></defs></svg></div>
          </div>
          <div className="kRiXyW1">
          <Lottie
      animationData={getStarted}
      loop={hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      title={AltTitle}
      alt={AltDescription}
    />
          </div>
          <div className="cbypVx1">Get Started</div></button></Link>
    
            </div>     
             
             </div>
              </div>  
        </SwiperSlide>
    ))}
      </Swiper>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-pagination"></div>
      </div>*/}
      <div id="block_f3af9e9cdaabd0a20ea76090256e0185" className="slider-wrapper slider-initialized" data-slides-desktop={3} data-carousel-name="HP why do business with Payoneer">
      <Slider ref={sliderRef} {...settings}>
        {applicationdata && applicationdata.map(({id,title,slug,para,animationData,AltTitle,AltDescription})=>(
             <div className="wp-block-group card border-2 me-3 m-3 p-4  is-style-blurred-bg has-payoneer-black-background-color has-background is-layout-flow wp-block-group-is-layout-flow" key={id} onMouseEnter={() => handleMouseEnter(id)}
                  onMouseLeave={() => handleMouseLeave(id)}>
               <Lottie
      animationData={animationData}
      loop={hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      className="thumbnail-image"
      title={AltTitle}
      alt={AltDescription}
      style={{width:"50px",height:"50px"}}
    />
             <div className="wp-block-group is-layout-flow wp-block-group-is-layout-flow">
              <div className="wp-block-groupbox">
               <Link to={`/solutions/${slug}`} style={{color:"#fff"}}><h3 className="wp-block-heading has-large-font-size"><strong>{title}</strong></h3></Link>
               <Link to={`/solutions/${slug}`} style={{color:"#fff"}}><p className="my-2 mb-4 has-payoneer-middle-grey-color has-text-color has-small-font-size">{para}</p></Link> 
        
  </div>
  <div className="row ggggg">        
          <Link to="https://hrm.thedatech.com/client-signup" target='_blank' className="mx-md-2"><GetStartedbtn btnvalue="Get Started" iconbtn={getStarted}/></Link>
    
            </div>
             </div>
           </div>
    ))}
      </Slider>
      <div className="slider-controls-wrapper">
    <div className="d-flex align-items-center justify-content-center mt-4 mt-md-0">
      <div className="slider-buttons //d-none //d-md-flex d-flex align-items-center">
        <div className="custom-slick-arrow previous d-flex align-item-center me-2 rounded-circle" onClick={() => sliderRef.current.slickPrev()} ><MdKeyboardArrowLeft className="level1-0162-arrow-left large" /></div>
        <div className="custom-slick-arrow next d-flex align-item-center rounded-circle"  onClick={() => sliderRef.current.slickNext()} ><MdOutlineKeyboardArrowRight className="level1-0160-arrow-right large" /></div>
      </div>
      <div className="slick-progress ms-2 ms-md-4" role="progressbar" aria-valuemin={0} aria-valuemax={100} aria-label="Progress bar for slideshow navigation" aria-valuenow={progress} style={{ backgroundSize: `${progress}% 100%` }}>
            <span className="slider__label">{progress}% completed</span>
          </div>
    </div>
  </div>
</div>

      {zoomImage && (
        <div className="large-image-container-card" onClick={hideLargeImageHandler}>
        <h1>{largepic.title}</h1>
        <div className="large-image-container" onClick={hideLargeImageHandler} onMouseEnter={()=>setisHover(true)}
        onMouseLeave={()=>setisHover(false)}>
        <Lottie
      animationData={largepic.pic}
      loop={isHover}
      autoPlay={isHover}
      className="large-image"
    />
        </div>
        <div className='d-flex justify-content-center'>
        <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="GET STARTED" iconbtn={<SiStartrek/>}/></Link>
        <Link to="/Services"><Custobtn btnvalue="Services" iconbtn={<FaBattleNet />}/></Link>
        
        </div>
      </div>
      )}
        </div>
    )
}