import './Testimonials.css'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { HowtogetStart } from '../Services/HowtogetStart';
import { FaStar} from "react-icons/fa6";
import { FcInternal } from "react-icons/fc";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Lottie from 'lottie-react';
import testimonials from '../../Component/FILEJSON/other pages and includes/testimonials.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import { useState } from 'react';
import {Helmet} from "react-helmet";
import data from './Data';

export const Testimonials=()=>{
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
    return(
        <div className='servicemargin'>
            <Helmet>
        <title>Client Reviews and Testimonials - Trusted International IT Company with Guaranteed Results</title>
        <meta name="description" content="Explore client reviews and testimonials for top-rated IT company, DA Tech. As a leading International IT
company, our clients attest to our guaranteed results and expertise in design, development and digital
marketing." />
        <meta name='keywords' content="international IT company, award winning IT company, top-rated digital marketing agency, guaranteed
results, client satisfaction, trusted IT company,"/>
     </Helmet>
            <div className='row mx-2'>
            <div className="col-1 mt-1"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-11 d-flex justify-content-md-center justify-content-end mt-1" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}>
              <Lottie
      animationData={testimonials}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      id="faqiconmain"
      style={{ width: 30, height: 30 }}
      className='mb-2'
    />
                <h1 className='subpagestitle pt-2' id="eightheight" style={{lineHeight:'18px'}}>Discover What Our Clients Say</h1></div>
           <p className='text-center paragraphmargin mb-0' >At DA Tech, client satisfaction is at the heart of everything we do. But don’t just take our word for it - see
what our clients have to say about their experiences with our top-rated IT services. Our unwavering
commitment to guaranteed results and expertise in digital marketing has earned us accolades across
platforms like Google, UpWork, Guru, Fiverr and more. Join the growing list of businesses that have
achieved success with DA Tech, a trusted International IT company.</p>
            </div>
            <Swiper
        slidesPerView={1}
        spaceBetween={10}
        className="mySwiper mt-0 p-3 mx-auto mb-0"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          }
        }}
      >
        {data && data.map(({id,Name,Position,Description,Company,Country,Logo,Linkto,Icon,AltTitle,AltDescription,widthlogo,heightlogo})=>{
          console.log('widthlogo',widthlogo)
          return(
            <SwiperSlide key={id}>
              <Link to={Linkto} target='__blank' style={{color:'#fff'}}>
            <div className='Testimonialscontainer'>
            <div className="row align-items-center">
    <div className="col d-flex align-items-center">
      <div id="flagbox">
        <img src={Country} alt="" title="" />
      </div>
      <div id="userpic">
        <img src={Icon} alt={AltDescription} title={AltTitle} />
      </div>
    </div>
    <div className="col-auto text-center mx-2">
      <div className="internalbox">
        <FcInternal />
      </div>
    </div>
  </div>
                       <div className='d-flex justify-content-center mt-2'><FaStar className='star' style={{color:'yellow'}}/> <FaStar className='star' style={{color:'yellow'}}/> <FaStar className='star' style={{color:'yellow'}}/> <FaStar className='star' style={{color:'yellow'}}/> <FaStar className='star' style={{color:'yellow'}}/></div>
                      <p className='p-2 pt-2 text-center'>{Description}</p>
                   <div className='text-center textup mt-3'>
                        <img src={Logo} className='comlogo'  style={{width:widthlogo,height:heightlogo}} alt={Description} title={`5 stars review for DA Tech by ${Name}`}/>
                       <div className='d-flex justify-content-center mt-1'>
                       <h4>{Name}</h4><h5>{Position}</h5>
                       </div>
                   </div>
                   </div>
                   </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
         <HowtogetStart/>
        </div>
    )
}