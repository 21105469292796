import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import { IoLogoFacebook,IoLogoLinkedin,IoLogoTwitter,IoLogoWhatsapp,IoLogoSkype } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";

export const BlogDetail=()=>{
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [Blogs,setBlogs]=useState([]);
  const [Blogdetail,setBlogdetail]=useState([]);
 
  console.log('Blogdetail',Blogdetail);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
      }));

      const { title } = useParams();
      console.log('title',title);

      const getBlogs=async()=>{
        try{
          const response=await axios.get('https://hrm.thedatech.com/api/blogs')
          setBlogs(response.data)
        }catch(err){
          console.log(err);
        }
      }
      const getBlogDetail=async(url)=>{
        try{
          const response=await axios.get(url)
          setBlogdetail(response.data)
        }catch(err){
          console.log(err);
        }
      }
      


      useEffect(()=>{
        getBlogs()
        getBlogDetail(`https://hrm.thedatech.com/api/blogs/detail/${title}`)
      },[])

    
    return(
        <div className='servicemargin'>
          <Helmet>
        <title>{Blogdetail.meta_title}</title>
        <meta name="description" content={Blogdetail.meta_dis} />
        <meta name="keywords" content={Blogdetail.meta_leywords}/>
     </Helmet>
              <div className='row mx-2'>
              <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/blogs'><Lottie
      animationData={back}
      loop={isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-8 d-flex justify-content-center" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><h1 className="text-center subpagestitle mb-0" style={{margin:"auto auto auto 14rem"}}>{Blogdetail.title}</h1></div>
              <div className="col-3">
              </div>
            </div>
            <div className='row'>
                <div className='col-md-6 blogdetailscroller'>
                <div
                className='pt-5 px-4'
            dangerouslySetInnerHTML={{
              __html: Blogdetail?.blog_dis}}
              style={{ color: '#fff !important' }}
          /> 
                </div>
                <div className='col-md-6 desktopview'>
                <div id="rightimage" style={{height:'55vh'}}><img src={`https://hrm.thedatech.com/${Blogdetail.image}`} alt={Blogdetail.img_alt_dis} title={Blogdetail.img_alt_text} /></div>
                 <div className='detailboxcontainer'>
                 <Swiper
        slidesPerView={2}
        spaceBetween={30}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          924: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
        className="mySwiper p-4"
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
      
      >
        {Blogs?.map(({id,title})=>(
    <SwiperSlide key={id}>
    <div className='relatedblogscard'>
    <Link to={`/Blogs/${title}`} style={{color:'#fff'}}><h4>{title}</h4></Link>
    </div>
 </SwiperSlide>
        ))}
      
      
      </Swiper>
                 </div>
                </div>
            </div>
        </div>
    )
}

/*Heading: Center Blog Title with Back icon at same line which redirects to all blogs page. and share icons at right corner. ( small icons: fb, instagram, linkedin, twitter, whatsapp, skype,)

right side:
Image
then recent blogs boxes at bottom of the image

left side scrollable section:
heading
4 line paragraph
heading
4 line paragraphheading
4 line paragraph */