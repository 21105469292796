import { useState } from "react";
import {Helmet} from "react-helmet";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import privacy from '../../Component/FILEJSON/other pages and includes/privacy-policy.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import getStarted from '../../Component/FILEJSON/how to get start/GetStarted.json'
import services from '../../Component/FILEJSON/services/services.json';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { Custobtn } from "../Application/Custobtn";
import { GetStartedbtn } from "../Application/Getstartedbtn";
import { FAQBOX } from "../FAQ/FAQBOX";
import tscs from "../../Component/FILEJSON/other pages and includes/t&c's.json";

const data=[{
  id:101,
   title:"Acceptance of Terms",
   question:"Acceptance of Terms",
   answer:"By accessing our website and engaging with our services, you agree to abide by these Terms and Conditions."
},
{
  id:102,
   title:"Geographic Limitations",
   question:"Geographic Limitations",
   answer:"DA Tech operates globally. We provide services in Africa, Central America, Europe, the Middle East, North America, the Pacific, and South America."
},
{
  id:103,
   title:"Services Offered",
   question:"Services Offered",
   answer:`<p>Explore our diverse range of services:</p><ul><li className='mx-3'>Custom Development Services</li><li className='mx-3'>Website Design and Development Solutions</li><li className='mx-3'>Mobile Application Development</li><li className='mx-3'>UI/UX and Graphic Designing</li><li className='mx-3'>SEO and ASO Services</li><li className='mx-3'>Google, LinkedIn, and YouTube Ads Management</li><li className='mx-3'>Social Media Marketing</li><li className='mx-3'>Content Writing</li><li className='mx-3'>Book and eBook Writing</li><li className='mx-3'>Web and App Maintenance and Support</li><li className='mx-3'>Live Chat Support and Sales</li></ul>`
},{
  id:104,
   title:"User Responsibilities",
   question:"User Responsibilities",
   answer:"Users are responsible for maintaining the confidentiality of their account information and for all activities occurring under their accounts."
},{
  id:105,
   title:"Intellectual Property",
   question:"Intellectual Property",
   answer:"All content and materials provided by DA Tech are protected by copyright and intellectual property laws."
},
{
  id:106,
   title:"Privacy Policy",
   question:"Privacy Policy",
   answer:"Users agree to the terms outlined in our Privacy Policy."
},{
  id:107,
   title:"Communication",
   question:"Communication",
   answer:"By providing your email address, you consent to receive communications from us related to your account and services."
},{
  id:108,
   title:"Termination of Services",
   question:"Termination of Services",
   answer:"DA Tech reserves the right to terminate services or suspend accounts at our discretion."
},{
  id:109,
   title:"Contact",
   question:"Contact",
   answer:`<p>For any inquiries regarding these Terms and Conditions, please contact us at <small>info@thedatech.com</small>. <br/>Thank you for choosing DA Tech for your digital journey!
</p>`
}]


export const TermsCondition=()=>{
    const [isback,setisback]=useState(false);
    const [isHover,setisHover]=useState(false);
    const [expanded, setExpanded] = useState(false);

    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    return(
        <div className='servicemargin'>
             <Helmet>
        <title>Top-rated IT Company | Terms and Conditions | DA Tech</title>
        <meta name="description" content="Review DA Tech's Terms and Conditions governing our services. Understand the guidelines for a seamless
collaboration towards your digital success." />
        <meta name="keywords" content="top-rated IT company, privacy policy, software company, digital success, africa, central america, europe,
middle east, north america, pacific, south america."/>
     </Helmet>
     <div className='row mx-2'>
            <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-11 d-flex justify-content-md-center justify-content-end" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><Lottie
      animationData={tscs}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 30, height: 30 }}
      className='mt-2 privicon'
    /><h1 className="subpagestitle mb-0 mx-1 mt-2" id="eightheight">DA Tech - Where Digital Excellence Begins!</h1></div>
       <p className="text-center mx-auto" style={{fontFamily:"CFNotche-Bold",width:"70%"}}>These Terms and Conditions outline the rules and regulations for the use of DA Tech's
       website and services, established as a sole proprietorship in 2018.</p>    
       <div className="faqboxcontainer2 mx-auto" style={{width:"70%"}}>
          {data && data.map(({id,title,question,answer})=>(
             <FAQBOX
 key={id}
question={question}
answer={answer}
i={id} expanded={expanded} setExpanded={setExpanded}
/>
          ))}
          </div>  
          <div className='d-flex justify-content-center mt-2 flex-wrap fixedcontainer1' id="removermob" style={{width:'100%'}}>
   <Link to="/privacypolicy" className="mx-3"><GetStartedbtn btnvalue="Privacy Policy" iconbtn={privacy}/></Link>
   <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="Get Started" iconbtn={getStarted}/></Link>
   <Link to="/services" className="mx-3"><Custobtn btnvalue="Services" iconbtn={services}/></Link>
 </div>
            </div>
        </div>
    )
}