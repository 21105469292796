import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { motion, AnimatePresence } from "framer-motion";

export const FAQBOX = ({ question, answer,i,setExpanded,expanded }) => {
  const isOpened = i === expanded;
  return (
    <div className="faqboxq">
      <motion.div className="faqboxf"  initial={false}
        animate={{ backgroundColor: isOpened ? "#ff6600" : "rgba(209, 210, 211, 0.39)" }}
        onClick={() => setExpanded(isOpened ? false : i)}>
        <h2>{question}</h2>
        <button>
          {isOpened ? <FaMinus /> : <FaPlus />}
        </button>
      </motion.div>
      <AnimatePresence initial={false}>
      {isOpened && (
         <motion.div className='parafaq' key="content"
         initial="collapsed"
         animate="open"
         exit="collapsed"
         variants={{
           open: { opacity: 1, height: "auto" },
           collapsed: { opacity: 0, height: 0 }
         }}
         transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <motion.p variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
    transition={{ duration: 0.8 }}><div
            dangerouslySetInnerHTML={{__html: answer}}
         /></motion.p>
  </motion.div>
      )}
      </AnimatePresence>
      
    </div>
  );
};


