const Data=[{
    id:1,
    Icon:"/carousel1/3.png",
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"From start to finish, they exhibited professionalism, technical expertise, and a strong commitment to delivering a top-notch product… They are a team of professionals it shows in the exceptional job they did on my online service booking app. Thank you, DA Tech team…",
    Position:'Founder',
    Name:'Aaron Yelenick',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:"https://www.guru.com/freelancers/da-tech/reviews",
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:2,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"Great and professional ! I'll work with him again soon!",
    Position:'CEO',
    Name:'Carlos Arias',
    Company:'Inspired Peru',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:"https://fiverr.com/datech",
    Logo:'/NewAdded/inspiredperu.png',
    widthlogo:'6rem',
    heightlogo:'2.5rem'
},{
    id:3,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"Great work done and on time. I think he is the. best among the rest.",
    Position:'CEO',
    Name:'Mathias Ackon',
    Company:'MBC Hub',
    Country:'/Flags/WestAfrica.png',
    Linkto:"https://fiverr.com/datech",
    Logo:'/NewAdded/mbchub.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:4,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'Amazing work done by Asad and his team for my ecommerce website and amazon store! 100% recommended',
    Position:'CEO',
    Name:'Syed Maaz',
    Company:'Damaaz',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:"https://g.co/kgs/kX1BCE",
    Logo:'/NewAdded/Damaaz.png',
    widthlogo:'12rem',
    heightlogo:'3rem'
},{
    id:5,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'Your Loyalty is your brand.',
    Position:'CEO',
    Name:'Rizwan Iqbal',
    Company:'KUN',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:"https://g.co/kgs/jk4PYz",
    Logo:'/NewAdded/KUN.png',
    widthlogo:'8rem',
    heightlogo:'2.9rem'
},{
    id:6,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"I had the pleasure of working with an excellent website developer recently, and I couldn't be happier with the results. They truly deserve a 5-star review for their outstanding work. Here's why: Exceptional Design Skills: The developer displayed a remarkable talent for creating visually stunning websites. The design they crafted for my project exceeded my expectations and immediately captured the essence of my brand. Technical Proficiency: Not only did they excel in design, but their technical skills were also top-notch. They implemented advanced features and functionality seamlessly, ensuring a smooth user experience. Effective Communication: Communication is key in any project, and this developer was excellent at it. They were responsive, attentive to my needs, and kept me updated throughout the entire process. It was a breeze working with them. Timely Delivery: Meeting deadlines is crucial in web development, and they delivered my project on time, if not ahead of schedule. This level of reliability is invaluable in the industry. Attention to Detail: It's the little things that make a website truly exceptional, and this developer paid meticulous attention to detail. Every element was finely tuned, and they went the extra mile to ensure everything worked flawlessly. In conclusion, I wholeheartedly recommend this website developer to anyone seeking top-quality web development services. Their combination of design prowess, technical expertise, communication skills, punctuality, and attention to detail make them a 5-star professional in every sense. I look forward to working with them on future projects.",
    Position:'Founder',
    Name:'Aaron Yelenick',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:"https://www.guru.com/freelancers/da-tech/reviews",
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:7,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:"Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'Great experience. Successfully completed the Calling software project DA tech completed the entire project professionally. During the progress they kept us upto date with the progress and handled with care.',
    Position:'AVP',
    Name:'Uzair Ali',
    Company:'Gharyal',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:"https://g.co/kgs/TL1Sc8",
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:8,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'It was amazing experience work with this digital marketing company. I am WordPress Developer in this company since 1 year. Contact this company because this is best company and what they promise this company definitely deliver',
    Position:'X-Employee',
    Name:'Ibad Ali',
    Company:'DA Tech',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:"https://g.co/kgs/YoTbXj",
    Logo:'/logo.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:9,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'Working as a Full Stack Developer at DA Tech has been an exceptional experience. I highly recommend DA Tech for its work ethics and environment.',
    Position:'X-Employee',
    Name:'Usama Akhtar',
    Company:'DA Tech',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:'https://g.co/kgs/KT1Mfn',
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:10,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'I feel very satisfied during working with DA Tech. Much wonderful and brilliant working space.',
    Position:'X-Employee',
    Name:'Saleem Iqbal',
    Company:'DA Tech',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:'https://g.co/kgs/UgxmEm',
    Logo:'/logo.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},
// },{
//     id:11,
//     Icon:'/googleicon.png',
//     AltTitle:"Results-Oriented and Trusted IT Company",
//     AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
//     Description:'',
//     Position:'X-Employee',
//     Name:'Zainy Zain',
//     Company:'DA Tech',
//     Country:'/NewFlags/PakistanFlag.gif',
//     Linkto:'https://g.co/kgs/Jyh98R',
//     Logo:'/logo.png',
//     widthlogo:'10rem',
//     heightlogo:'2.2rem'
// },
{
    id:12,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:"I had the pleasure of working as a remote Laravel developer with DA Tech for several months and I can confidently say that it was an outstanding experience.",
    Position:'X-Employee',
    Name:'Rais ur Rehman',
    Company:'DA Tech',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:'https://g.co/kgs/TVjX2P',
    Logo:'/logo.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:15,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:'Asad did a fantastic job on our landing page design. We built it with Elementor based on a competing site. It looks great and is ready for our copy writer. Thank you',
    Position:'Founder',
    Name:'Justin Kunst',
    Company:'',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo:'/logo.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:18,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:'Asad has a great attitude and he is very good at what he does. I look forward to continuing to work with him.',
    Position:'Founder',
    Name:'Aaron Yelenick',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:19,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:'Keep up the good work!',
    Position:'Founder',
    Name:'Aaron Yelenick',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo:'/NewAdded/ATSFSN.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:20,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:'DA Tech has efficiently designed a pixel perfect website for my staffing business. Thank you and good luck! 100% Recommended',
    Position:'Founder',
    Name:'Akif Ahmed',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/mcleanAkifAhmed.png',
    widthlogo:'8rem',
    heightlogo:'2.8rem'
},{
    id:21,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"Choosing this company for app development was a life changing for us. The app works speedy and sales got increase instantly.",
    Position:'Founder',
    Name:'Mohamad Hasan',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo: '/NewAdded/sayches.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:22,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"We were impressed with DA Tech expertise in Ionic for our app development.",
    Position:'Founder',
    Name:'Bernard',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/Adfidians.png',
    widthlogo:'8rem',
    heightlogo:'2.2rem'
},{
    id:23,
    Icon:'/carousel1/2.png',
    AltTitle:"Exceptional Client Experiences - Top-Rated IT Services",
    AltDescription:" Discover exceptional client experiences through Upwork reviews for DA Tech. Our services are tailored for success, ensuring satisfied clients with proven results.",
    Description:" my custom development website was done by DA Tech and went above and beyond. Their team delivered an application that helps getting aligned everything for a professional.",
    Position:'Founder',
    Name:'Alan Abas',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.upwork.com/freelancers/~0121b5ac3c5d855695',
    Logo: '/NewAdded/AlanAbas.png',
    widthlogo:'7rem',
    heightlogo:'2.5rem'
},{
    id:24,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:'Our experience with Asad was fantastic. The custom application his team build for me exceeds my expectations. Wonderful job!',
    Position:'Founder',
    Name:'Miguel',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/Bleufonces.png',
    widthlogo:'14rem',
    heightlogo:'3rem'
},{
    id:25,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"DA Tech expertise in mobile app development is truly marvelous. The Flutter app they created for us not only looks good but also increase the users.",
    Position:'Founder',
    Name:'Andrea Kreider',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo: '/NewAdded/XMedAndrea.png',
    widthlogo:'12rem',
    heightlogo:'2.5rem'
},{
    id:26,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:'Choosing DA Tech for website development was the best decision we made for our business. Their attention to detail and technical expertise ensured a flawless end product',
    Position:'CEO',
    Name:'Sofi',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo:'/NewAdded/cleaneatzSofi.png',
    widthlogo:'9rem',
    heightlogo:'2.5rem'
},{
    id:27,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:" Choosing this company for our Laravel website was a wise decision. Their development team is proficient in Laravel.",
    Position:'President',
    Name:'Mmduh',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo:'/NewAdded/denali.png',
    widthlogo:'7rem',
    heightlogo:'2.9rem'
},{
    id:28,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"The WordPress site developed by DA Tech is a testament to their skill. The team's attention to detail and use of WordPress plugins made our site both functional and beautiful.",
    Position:'AVP',
    Name:'Ray',
    Company:'ATS FSN',
    Country:'/NewFlags/UKFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/derma.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:29,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"Our experience with DA Tech was outstanding. The website they crafted is not just visually appealing but also generating revenue.",
    Position:'Director',
    Name:'Mark Kershaw',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/FAIB-.png',
    widthlogo:'9rem',
    heightlogo:'2.5rem'
},
// {
//     id:13,
//     Icon:'/googleicon.png',
//     AltTitle:"Results-Oriented and Trusted IT Company",
//     AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
//     Description:'',
//     Position:'X-Employee',
//     Name:'Huma Asim',
//     Company:'DA Tech',
//     Country:'/NewFlags/PakistanFlag.gif',
//     Linkto:'https://g.co/kgs/n5zW2J',
//     Logo:'/logo.png',
//     widthlogo:'10rem',
//     heightlogo:'2.2rem'
// },
// {
//     id:14,
//     Icon:'/googleicon.png',
//     AltTitle:"Results-Oriented and Trusted IT Company",
//     AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
//     Description:'',
//     Position:'X-Employee',
//     Name:'Mohsin Ehtisham',
//     Company:'DA Tech',
//     Country:'/NewFlags/PakistanFlag.gif',
//     Linkto:'https://g.co/kgs/yKVKCx',
//     Logo:'/logo.png',
//     widthlogo:'10rem',
//     heightlogo:'2.2rem'
// },
{
    id:30,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"DA Tech's Laravel development team exceeded our expectations. The custom features they implemented on our website have significantly improved user engagement. ",
    Position:'Director',
    Name:'Sofi',
    Company:'Juwon Olugboye',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/firstwork.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:31,
    Icon:'/carousel1/2.png',
    AltTitle:"Exceptional Client Experiences - Top-Rated IT Services",
    AltDescription:" Discover exceptional client experiences through Upwork reviews for DA Tech. Our services are tailored for success, ensuring satisfied clients with proven results.",
    Description:"Asad’s commitment to delivering a custom website was marvelous. He did everything perfectly and 100% as per my requirements. I will hire you again!",
    Position:'Founder',
    Name:'Bilal Ali',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.upwork.com/freelancers/~0121b5ac3c5d855695',
    Logo: '/NewAdded/hi5_consulting.png',
    widthlogo:'6rem',
    heightlogo:'2.5rem'
},
,{
    id:32,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"Development team exceeded our expectations, all of the custom features they implemented on our website have significantly improved the traffic and sales. ",
    Position:'CEO',
    Name:'Henry Abraham',
    Company:'ATS FSN',
    Country:'/NewFlags/GhanaFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/hjaafrica.png',
    widthlogo:'6rem',
    heightlogo:'2.6rem'
},{
    id:16,
    Icon:'/googleicon.png',
    AltTitle:"Results-Oriented and Trusted IT Company",
    AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
    Description:'',
    Position:'X-Employee',
    Name:'Abdul Samad',
    Company:'DA Tech',
    Country:'/NewFlags/PakistanFlag.gif',
    Linkto:'https://g.co/kgs/khu4H8',
    Logo:'/logo.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:33,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"The CRM system developed by DA Tech has truly transformed our operations. We appreciate their commitment to the quality and personalized service.",
    Position:'CEO',
    Name:'Tahir Khan',
    Company:'ATS FSN',
    Country:'/NewFlags/SaudiaFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo: '/NewAdded/HMR.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:34,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"DA Tech's mobile app development service stands out. The app they created for us is not only functional but also visually stunning, thanks to their use of Flutter and React Native.",
    Position:'Director',
    Name:'Oliver Wheelen',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/homeplace.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:35,
    Icon:'/carousel1/2.png',
    AltTitle:"Exceptional Client Experiences - Top-Rated IT Services",
    AltDescription:" Discover exceptional client experiences through Upwork reviews for DA Tech. Our services are tailored for success, ensuring satisfied clients with proven results.",
    Description:"Our website developed by DA Tech, is a true reflection of our brand. The HTML and CSS details create a seamless user experience that we're proud of.",
    Position:'Founder',
    Name:'Bilal Ali',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.upwork.com/freelancers/~0121b5ac3c5d855695',
    Logo: '/NewAdded/LetsHireBilalAli.png',
    widthlogo:'10rem',
    heightlogo:'2.2rem'
},{
    id:36,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"I am very impressed with social media marketing service and it is a boost for our brand. Their strategic approach to social media has significantly increased our online presence, sales and engagement",
    Position:'Founder',
    Name:'Lorna Green',
    Company:'ATS FSN',
    Country:'/NewFlags/UKFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/LGY_White.png',
    widthlogo:'5rem',
    heightlogo:'2.6rem'
},{
    id:37,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"Our landing page designed by DA Tech in Figma perfectly captures our brand identity. It's modern, sleek, and precisely what we asked.",
    Position:'Founder',
    Name:'Miguel',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo: '/NewAdded/Life-water-Solutions-logo-ver-4.png',
    widthlogo:'8rem',
    heightlogo:'2.5rem'
},{
    id:38,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:" Choosing this company for website design was a smart move. They use React.js and made our site loaded on a click, now our page doesn’t take time to load as it opens on a click.",
    Position:'CEO',
    Name:'Rehan Arain',
    Company:'ATS FSN',
    Country:'/NewFlags/UKFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/LocalTasker.png',
    widthlogo:'8rem',
    heightlogo:'2.5rem'
},{
    id:39,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"Team is very professional and started on my project instantly. Thank you Asad for making this project completed!",
    Position:'Founder',
    Name:'Maryame Saqib',
    Company:'',
    Country:'/NewFlags/UKFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/MeerakiMaryameSaqib.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:40,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"Our journey with DA Tech's mobile app development team was incredible. The app they built using Swift exceeded our expectations in terms of both functionality and design.",
    Position:'CEO',
    Name:'Rehan Arain',
    Company:'ATS FSN',
    Country:'/NewFlags/UKFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/musgreet.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:41,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"Team is very professional and started on my project instantly. Thank you Asad for making this project completed!",
    Position:'Founder',
    Name:'Mohamad Hasan',
    Company:'ATS FSN',
    Country:'/NewFlags/UAEFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/peerhunter.png',
    widthlogo:'9rem',
    heightlogo:'2.2rem'
},{
    id:42,
    Icon:'/logo.png',
    AltTitle:"Professional Endorsements on LinkedIn - Quality IT Services",
    AltDescription:"Explore professional endorsements on LinkedIn showcasing DA Tech's commitment to delivering top-quality IT services. Clients recognize us for excellence and results.",
    Description:"DA Tech's WordPress expertise shone through in the development of our website. The result is a user-friendly, visually stunning site that perfectly aligns with our brand.",
    Position:'CEO',
    Name:'Chris Wilson',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/re-recycling.png',
    widthlogo:'6rem',
    heightlogo:'2.5rem'
},{
    id:43,
    Icon:'/googleicon.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"Our online presence is seamless and always up-to-date.",
    Position:'CEO',
    Name:'Shafaq Rasekh',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'',
    Logo: '/NewAdded/BayPassDrivingSchool.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:44,
    Icon:'/carousel1/11.png',
    AltTitle:"Trusted Digital Marketing Experts with Proven Results",
    AltDescription:"Positive reviews and testimonials from satisfied clients of DA Tech. Emphasizing toprated digital marketing experts, client satisfaction, and trusted IT services with guaranteed success.",
    Description:"The Laravel website was done by DA Tech and it still works fast and perfect.",
    Position:'Founder',
    Name:'Samir Kadi',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://fiverr.com/datech',
    Logo: '/NewAdded/TechataClick.png',
    widthlogo:'10rem',
    heightlogo:'2.5rem'
},{
    id:45,
    Icon:'/carousel1/3.png',
    AltTitle:"Satisfaction and Guaranteed Success with a Top-Rated IT Company",
    AltDescription:"Client expressing satisfaction with DA Tech's services. Highlighting a top-rated IT company known for guaranteed results and client satisfaction.",
    Description:"The website they created for us on wordpress not only looks great but also delivers a seamless user experience.",
    Position:'CEO',
    Name:'Jose Titus',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.guru.com/freelancers/da-tech/reviews',
    Logo: '/NewAdded/TexasinetJoseTitus.png',
    widthlogo:'10rem',
    heightlogo:'2.7rem'
},{
    id:46,
    Icon:'/carousel1/2.png',
    AltTitle:"Exceptional Client Experiences - Top-Rated IT Services",
    AltDescription:" Discover exceptional client experiences through Upwork reviews for DA Tech. Our services are tailored for success, ensuring satisfied clients with proven results.",
    Description:"Our React.js-powered application developed by DA Tech",
    Position:'Director',
    Name:'Samuel Muoto',
    Company:'ATS FSN',
    Country:'/NewFlags/USAFlag.gif',
    Linkto:'https://www.upwork.com/freelancers/~0121b5ac3c5d855695',
    Logo: '/NewAdded/Wayaglobal.png',
    widthlogo:'8rem',
    heightlogo:'2.2rem'
}]
// },{
//     id:17,
//     Icon:'/googleicon.png',
//     AltTitle:"Results-Oriented and Trusted IT Company",
//     AltDescription:" Clients celebrating the guaranteed results delivered by DA Tech. Focus on client satisfaction, trusted IT services, and a results-oriented approach.",
//     Description:'',
//     Position:'X-Employee',
//     Name:'Haseeb Hussain',
//     Company:'DA Tech',
//     Country:'/NewFlags/PakistanFlag.gif',
//     Linkto:'https://g.co/kgs/Yr2tUw',
//     Logo:'/logo.png',
//     widthlogo:'10rem',
//     heightlogo:'2.2rem'
// }]

export default Data;