import { Custobtn } from "../Application/Custobtn";
import { SiStartrek } from "react-icons/si"; 
import { Link } from 'react-router-dom';
import './portfolio.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards,Navigation} from 'swiper/modules';
import { GetStartedbtn } from "../Application/Getstartedbtn";
import { FaBattleNet} from "react-icons/fa6";
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Lottie from 'lottie-react';
import portfolio from '../../Component/FILEJSON/portfolio/portfolio.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import getStarted from '../../Component/FILEJSON/other pages and includes/Getstarted.json';
import services from '../../Component/FILEJSON/portfolio/portfolio.json'
import customer from '../../Component/FILEJSON/services/custom.json'
import website from '../../Component/FILEJSON/services/website.json'
import mobileapp from '../../Component/FILEJSON/services/mobileapp.json'
import ebook from '../../Component/FILEJSON/services/ebook.json'
import googlelinkedin from '../../Component/FILEJSON/services/google linkedin youtube ads.json'
import socialmedia from '../../Component/FILEJSON/services/smm.json'
import uiux from '../../Component/FILEJSON/services/home design.json'
import seo from '../../Component/FILEJSON/services/home marketing.json'
import plus from '../../Component/FILEJSON/portfolio/portfolio and application pages boxes for image to zoom.json';
import {Helmet} from "react-helmet";
import Data from "./data";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

const portfoliodata=[{
  id:1,
  title:"Custom Development",
  pic:"/carousel1/1.png"
},
{
  id:2,
  title:"Custom Development",
  pic:"/carousel1/2.png"
},
{
  id:3,
  title:"Custom Development",
  pic:"/carousel1/3.png"
},{
  id:4,
  title:"Custom Development",
  pic:"/carousel1/4.png"
},{
  id:5,
  title:"Custom Development",
  pic:"/carousel1/5.png"
},{
  id:6,
  title:"Custom Development",
  pic:"/carousel1/6.png"
},{
  id:7,
  title:"Custom Development",
  pic:"/carousel1/7.png"
},{
  id:8,
  title:"Custom Development",
  pic:"/carousel1/8.png"
},{
  id:9,
  title:"Custom Development",
  pic:"/carousel1/9.png"
},
,{
  id:10,
  title:"Websites",
  pic:"/carousel1/10.png"
},{
  id:11,
  title:"Websites",
  pic:"/carousel1/11.png"
},
{
  id:12,
  title:"Websites",
  pic:"/carousel1/1.png"
},{
  id:13,
  title:"Websites",
  pic:"/carousel1/2.png"
},
{
  id:14,
  title:"Websites",
  pic:"/carousel1/3.png"
},{
  id:15,
  title:"Websites",
  pic:"/carousel1/4.png"
},{
  id:16,
  title:"Websites",
  pic:"/carousel1/5.png"
},{
  id:17,
  title:"Websites",
  pic:"/carousel1/6.png"
},
{
  id:18,
  title:"Mobile Apps",
  pic:"/carousel1/7.png"
},{
  id:19,
  title:"Mobile Apps",
  pic:"/carousel1/8.png"
},{
  id:20,
  title:"Mobile Apps",
  pic:"/carousel1/9.png"
},{
  id:21,
  title:"UIUX",
  pic:"/carousel1/10.png"
},
,{
  id:22,
  title:"UIUX",
  pic:"/carousel1/1.png"
},{
  id:23,
  title:"SEO and ASO",
  pic:"/carousel1/2.png"
},{
  id:24,
  title:"SEO and ASO",
  pic:"/carousel1/3.png"
},
{
  id:25,
  title:'Graphic Designing',
  pic:"/carousel1/4.png"
},
{
  id:26,
  title:'Google, YouTube and LinkedIn Ads',
  pic:"/carousel1/5.png"
},
{
  id:27,
  title:'Google, YouTube and LinkedIn Ads ',
  pic:"/carousel1/2.png"
},
{
  id:28,
  title:'Google, YouTube and LinkedIn Ads ',
  pic:"/carousel1/3.png"
},{
  id:29,
  title:'Social Media Marketing',
  pic:"/carousel1/4.png"
},{
  id:30,
  title:'Social Media Marketing',
  pic:"/carousel1/7.png"
}]

const servicesmenu = [
  {id:0,animationData:customer},
  {id:1, animationData: website},
  { id:2, animationData: mobileapp},
  {id:3, animationData: uiux},
  {id:4, animationData: seo},
  {id:5, animationData: googlelinkedin},
  {id:6, animationData: socialmedia},
  {id:7, animationData: ebook}
]


const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [zoomImage,setZoomImage]=useState(false);
  const [largepic,setlargepic]=useState({title:'',data:[]});
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
 
  const handleClose = () => setZoomImage(false);

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id)
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };
  const showLargeImageHandler = (item) => {
    console.log('item', item);
    setZoomImage(true);
    setlargepic({ title: item.category, data: item });
  };
  
  const hideLargeImageHandler = () => {
    setZoomImage(false);
    setlargepic({ title: '', data: [] });
  };
      const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
      }));

  const portfolioCategory=async()=>{
      try{
           const res=await axios.get('https://hrm.thedatech.com/api/portfolio/categories', {
            headers: {
              'Accept': 'application/json', // Set the Accept header
            },
          });
           const response=await axios.get('https://hrm.thedatech.com/api/portfolio',{
            headers: {
              'Accept': 'application/json', // Set the Accept header
            },
          });
           setCategory(res.data.Portfolio_Categories);
           setPortfolioItems(response.data.data);
           }catch(err){
            console.log(err)
           }
          }

          useEffect(()=>{
                    portfolioCategory()
                  },[])

    const categories = Object.keys(portfolioItems);

       console.log('categories',categories)

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    // const getImagesToDisplay = () => {
    //   if (selectedCategory === 'All') {
    //     console.log('Object.values(portfolioItems)',Object.keys(portfolioItems))
    //     return Object.values(portfolioItems).flat();
    //   }
    //   return portfolioItems[selectedCategory] || [];
    // };
  
    // const imagesToDisplay = getImagesToDisplay();


   // Convert portfolioItems object to an array
   const groupedItems = Object.entries(portfolioItems).map(([categoryName, items]) => ({
    categoryName,
    items,
  }));

  // Filter items based on selected category
  const getFilteredItems = () => {
    if (selectedCategory === 'All') {
      return groupedItems; // Return all items grouped by category
    }
    
    // Filter to show only the selected category
    return groupedItems.filter(group => group.categoryName === selectedCategory);
  };

  const filteredItems = getFilteredItems();





          const handleCategoryClick = (categoryName) => {
        setSelectedCategory(categoryName === selectedCategory ? null : categoryName);
      };

  return(
    <div className="servicemargin">
            <Helmet>
          <title>Top-Rated IT Company Portfolio | Work Proof and Client Satisfaction| DA Tech</title>
        <meta name="description" content="Impressive portfolio showcasing the work proof and client’s satisfaction in design, development and digital marketing services by Top-Rated IT Company. USA - CA - EU - UAE - PK - AU - NZ" />
          <meta name="keywords" content="Work proof IT company, top-rated IT company, international projects, success proof"/>
       </Helmet>
          <div className="row mx-2">
        <div className="col-1 mt-1"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
          onMouseLeave={()=>setisback(false)}>
                  <Link to='/'><Lottie
          animationData={back}
          loop={!isback}
          isStopped={!isback}
          autoplay={isback}
          style={{ width: 40, height: 40}}
          id='backbtnport'
        /></Link></BootstrapTooltip></div>
            <div className="col-11">
            <h1 className="justify-content-md-center justify-content-end  mb-0 d-flex subpagestitle">Success Proof | Portfolio</h1>
        <p className='mb-0 paragraphmargin' style={{fontFamily:"CFNotche-Bold"}}>Our portfolio reflects our unwavering dedication to delivering work proof and ensuring on-time project
    completions. Explore our diverse range of success proof stories across design, development and digital
    marketing. We take pride in turning ideas into reality, consistently surpassing client expectations across
    United States, Canada, Europe, United Arab Emirates, Pakistan, Australia and New Zealand.</p>
            </div>
            </div>
            <div className="btnsergridcontainer">   
                  <div className="btnsergrid">
                  <div className='mx-4'></div>
                  <BootstrapTooltip title={'All'} onMouseEnter={() => handleMouseEnter1(true)}
                  onMouseLeave={() => handleMouseLeave1(false)} onClick={() => handleCategoryClick('All')}>
                          <Link  style={{color:'#ff6600'}}>
                          <Lottie
                  animationData={services}
                  loop={!hoverStatesmenu}
                  isStopped={!hoverStatesmenu}
                  autoplay={hoverStatesmenu}
                  style={{ width: 30, height: 30}}
                /></Link></BootstrapTooltip>
                  {categories.map((name,index)=>{
                    console.log('name',name)
                 return(
                  <BootstrapTooltip title={name} onMouseEnter={() => handleMouseEnter1(index)}
                  onMouseLeave={() => handleMouseLeave1(index)} key={index} onClick={()=>handleCategoryClick(name)}>
                          <Link  style={{color:'#ff6600'}}>
                            {servicesmenu.map((menu)=>(
                              <div>
                               {menu.id===index?<Lottie
                  animationData={menu.animationData}
                  loop={hoverStatesmenu === menu.id}
                isStopped={hoverStatesmenu !== menu.id}
                autoplay={hoverStatesmenu === menu.id}
                  style={{ width: 30, height: 30}}
                />:""}
                              </div>
                            ))}
                          </Link></BootstrapTooltip>
                 )
                })}
                 </div>
              </div>
             <div className="portfolioboxcontainer mt-2">
             <div className="portfoliocontainer">
             {filteredItems?.map(({ categoryName, items }) => (
                <div className="portfoliocard" key={categoryName}>
                <Swiper
                  effect={'cards'}
                  grabCursor={true}
                  modules={[EffectCards]}
                  className="mySwiper"
                >
                  {items?.map((item) => (
                      <SwiperSlide key={item.id}>
                      <div className="portfoliocardcontent">
                      <div className="portfolioimagecard">
                        <img src={item.main_image} alt={item.main_des} title={item.main_alt}/>
                    
                      <button className="portfolio-zoom-icon" style={{background:"transparent",border:'none'}} onClick={() => {
                    if (item && item.categotry_name && item.extra_data) {
                    setlargepic({ title: item.categotry_name, data:item.extra_data, itemData:item });
                    setZoomImage(true)
                    }
                    }} onClickCapture={()=>{setZoomImage(true)}}><Lottie
                    animationData={plus}
                    loop={!isHover}
                    isStopped={!isHover}
                    autoplay={isHover}
                    style={{ width: 30, height: 30 }}  /></button>
                      </div>
                      </div>
                      </SwiperSlide>
                    ))}
                   
                </Swiper>
                <h2>{categoryName}</h2>
                </div>
             ))}
          </div>
             </div>
             <div className='d-flex justify-content-center mt-3 fixedcontainer1 gap-3 gap-md-3' style={{width:'100%',flexWrap:"wrap"}}>
          <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="GET STARTED" iconbtn={getStarted}/></Link>
          <Link to="/Services"><Custobtn btnvalue="Services" iconbtn={services}/></Link>
          </div>
      { zoomImage &&(
        <Modal show={zoomImage} onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>{largepic && largepic.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <div className="large-image-container" onClick={hideLargeImageHandler}>
           {/* {largepic.data && Array.isArray(largepic.data) && largepic.data.length !== 0 && largepic.data.map(({extra_alt, extra_des,extra_image})=>{
              console.log('largepic',largepic.itemData)
              return(
                    <SwiperSlide>
                    <img className="large-image" src={extra_image} alt={extra_des} title={extra_alt} />
                    </SwiperSlide>
              
              )
            })}  */}

         {largepic.itemData && (
          <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          direction="horizontal"
        >
          {/* Main Image Slide */}
          <SwiperSlide>
            <img
              className="large-image"
              src={largepic?.itemData?.main_image}
              alt={largepic?.itemData?.main_des} // Using main_des for alt
              title={largepic?.itemData?.main_alt} // Using main_alt for title
              style={{ width: '100%' }} // Ensure the image takes full width
            />
          </SwiperSlide>
        </Swiper>
         )}
         {largepic?.itemData?.extra_data && Array.isArray(largepic?.itemData?.extra_data) && largepic?.itemData?.extra_data.length !== 0 && (
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          direction="vertical" // Set this swiper to vertical
          style={{ height: '500px', marginTop: '20px' }} // Adjust height for vertical scrolling
        >
          {/* Map over extra_data to display each image */}
          {largepic?.itemData?.extra_data.map(({ extra_image, extra_alt, extra_des }, index) => (
            <SwiperSlide key={index}>
              <img
                className="extra-image"
                src={extra_image}
                alt={extra_des} // Using extra_des for alt
                title={extra_alt} // Using extra_alt for title
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
            </div>  
            </Swiper>
            </Modal.Body>
         
            </Modal>
          )}
          </div>
    
  )
}

export default Portfolio;

// function Portfolio() {
//   const [zoomImage,setZoomImage]=useState(false);
//   const [largepic,setlargepic]=useState({title:'',data:[]});
//   const [isHover,setisHover]=useState(false);
//   const [isback,setisback]=useState(false);
//   const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
//   const [category, setCategory] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [portfolioItems, setPortfolioItems] = useState([]);
//   console.log('portfolioItems',portfolioItems);

//   const handleClose = () => setZoomImage(false);

//   const handleMouseEnter1 = (id) => {
//     setHoverStatesmenu(id)
//   };

//   const handleMouseLeave1 = () => {
//     setHoverStatesmenu(null);
//   };
//   const showLargeImageHandler = (item) => {
//     console.log('item', item);
//     setZoomImage(true);
//     setlargepic({ title: item.category_name, data: item.extra_data });
//   };
  
//   const hideLargeImageHandler = () => {
//     setZoomImage(false);
//     setlargepic({ title: '', data: [] });
//   };
//       const BootstrapTooltip = styled(({ className, ...props }) => (
//         <Tooltip {...props} arrow classes={{ popper: className }} />
//       ))(({ theme }) => ({
//         [`& .${tooltipClasses.arrow}`]: {
//           color: theme.palette.common.black,
//         },
//         [`& .${tooltipClasses.tooltip}`]: {
//           backgroundColor: theme.palette.common.black,
//         },
//       }));

//       const portfolioCategory=async()=>{
//        try{
//        const res=await axios.get('https://hrm.thedatech.com/api/portfolio/categories', {
//         headers: {
//           'Accept': 'application/json', // Set the Accept header
//         },
//       });
//        const response=await axios.get('https://hrm.thedatech.com/api/portfolio',{
//         headers: {
//           'Accept': 'application/json', // Set the Accept header
//         },
//       });
//        setCategory(res.data.Portfolio_Categories);
//        setPortfolioItems(response.data.data);
//        }catch(err){
//         console.log(err)
//        }
//       }

//       const handleCategoryClick = (categoryName) => {
//         setSelectedCategory(categoryName === selectedCategory ? null : categoryName);
//       };


//       const filterPortfolioItems = () => {
//         if (selectedCategory === null) {
//           return portfolioItems;
//         } else {
//           return portfolioItems.filter(item => item.category_name === selectedCategory);
//         }
//       };

//       useEffect(()=>{
//         portfolioCategory()
//       },[])

//     return(
//       <div className="servicemargin">
//           <Helmet>
//         <title>Top-Rated IT Company Portfolio | Work Proof and Client Satisfaction| DA Tech</title>
//         <meta name="description" content="Impressive portfolio showcasing the work proof and client’s satisfaction in design, development and digital marketing services by Top-Rated IT Company. USA - CA - EU - UAE - PK - AU - NZ" />
//         <meta name="keywords" content="Work proof IT company, top-rated IT company, international projects, success proof"/>
//      </Helmet>
//         <div className="row mx-2">
//        <div className="col-1 mt-1"><BootstrapTooltip title="Back" onMouseEnter={()=>setisback(true)}
//       onMouseLeave={()=>setisback(false)}>
//               <Link to='/'><Lottie
//       animationData={back}
//       loop={!isback}
//       isStopped={!isback}
//       autoplay={isback}
//       style={{ width: 40, height: 40}}
//       id='backbtnport'
//     /></Link></BootstrapTooltip></div>
//         <div className="col-11">
//         <h1 className="justify-content-md-center justify-content-end  mb-0 d-flex subpagestitle" onMouseEnter={()=>setisHover(true)}
//       onMouseLeave={()=>setisHover(false)}><Lottie
//       animationData={portfolio}
//       loop={!isHover}
//       isStopped={!isHover}
//       autoplay={isHover}
//       style={{ width: 30, height: 30 }}
//       id="faqiconmain"
//       className='mb-2 mx-md-1 portgi'
//     />Success Proof | Portfolio</h1>
//     <p className='mb-0 paragraphmargin'>Our portfolio reflects our unwavering dedication to delivering work proof and ensuring on-time project
// completions. Explore our diverse range of success proof stories across design, development and digital
// marketing. We take pride in turning ideas into reality, consistently surpassing client expectations across
// United States, Canada, Europe, United Arab Emirates, Pakistan, Australia and New Zealand.</p>
//         </div>
//         </div>
//         <div className="btnsergridcontainer">   
//               <div className="btnsergrid">
//               <div className='mx-4'></div>
//               <BootstrapTooltip title={'All'} onMouseEnter={() => handleMouseEnter1(true)}
//               onMouseLeave={() => handleMouseLeave1(false)} onClick={() => handleCategoryClick(null)}>
//                       <Link  style={{color:'#ff6600'}}>
//                       <Lottie
//               animationData={services}
//               loop={!hoverStatesmenu}
//               isStopped={!hoverStatesmenu}
//               autoplay={hoverStatesmenu}
//               style={{ width: 30, height: 30}}
//             /></Link></BootstrapTooltip>
//               {category.map(({id,name})=>{
//              return(
//               <BootstrapTooltip title={name} onMouseEnter={() => handleMouseEnter1(id)}
//               onMouseLeave={() => handleMouseLeave1(id)} key={id} onClick={()=>handleCategoryClick(name)}>
//                       <Link  style={{color:'#ff6600'}}>
//                         {servicesmenu.map((menu)=>(
//                           <div>
//                            {menu.id===id?<Lottie
//               animationData={menu.animationData}
//               loop={!hoverStatesmenu===menu.id}
//               isStopped={!hoverStatesmenu!==menu.id}
//               autoplay={hoverStatesmenu===menu.id}
//               style={{ width: 30, height: 30}}
//             />:""}
//                           </div>
//                         ))}
//                       </Link></BootstrapTooltip>
//              )
//             })}
//              </div>
//           </div>
//          <div className="portfolioboxcontainer mt-2">
//          <div className="portfoliocontainer">
//          {filterPortfolioItems().map((item) => {
//           const { category_name, extra_data } = item;
//           return(
//             <div className="portfoliocard" key={item.id}>
//             <Swiper
//               effect={'cards'}
//               grabCursor={true}
//               modules={[EffectCards]}
//               className="mySwiper"
//             >
//              {item.extra_data && item.extra_data.map(({id,extra_image,extra_alt,extra_des})=>{  return(
//   <SwiperSlide>
//   <div className="portfoliocardcontent">
//   <div className="portfolioimagecard">
//        <img src={extra_image} alt={extra_des} title={extra_alt} id={id}/>

//   <button className="portfolio-zoom-icon" style={{background:"transparent",border:'none'}} onClick={() => {
// if (item && item.category_name && item.extra_data) {
// setlargepic({ title: category_name, data:extra_data});
// setZoomImage(true)
// }
// }} onClickCapture={()=>{setZoomImage(true)}}><Lottie
// animationData={plus}
// loop={!isHover}
// isStopped={!isHover}
// autoplay={isHover}
// style={{ width: 30, height: 30 }}  /></button>
//   </div>
//   </div>
//   </SwiperSlide>
//               )
//               })}
//             </Swiper>
//             <h2>{item.category_name}</h2>
//             </div>
//           )
// })}
//       </div>
//          </div>
//          <div className='d-flex justify-content-center mt-3 fixedcontainer1 gap-3 gap-md-3' style={{width:'100%',flexWrap:"wrap"}}>
//       <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="GET STARTED" iconbtn={getStarted}/></Link>
//       <Link to="/Services"><Custobtn btnvalue="Services" iconbtn={services}/></Link>
//       </div>
//   { zoomImage &&(
//     <Modal show={zoomImage} onHide={handleClose}>
//       <Modal.Header closeButton>
//           <Modal.Title>{largepic && largepic.title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//         <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
//         <div className="large-image-container" onClick={hideLargeImageHandler}>
//        {largepic.data && Array.isArray(largepic.data) && largepic.data.length !== 0 && largepic.data.map(({extra_alt, extra_des, extra_image})=>{
//           return(
//                 <SwiperSlide>
//                 <img className="large-image" src={extra_image} alt={extra_des} title={extra_alt} />
//                 </SwiperSlide>
          
//           )
//         })} 
//         </div>  
//         </Swiper>
//         </Modal.Body>
     
//         </Modal>
//       )}
//       </div>
//     )
//     }
    
//     export default Portfolio;

    /*  <div className="portfoliocontainer bg-white mt-3">
           <div className="portfoliocard">
                  <div className="portfolioimagecard">
                        <img src="/carousel1/1.png" alt="portfolio1"/>
                  </div>
                  <h2>Card heading</h2>
            </div>
            <div className="portfoliocard">
                  <div className="portfolioimagecard">
                        <img src="/carousel1/1.png" alt="portfolio1"/>
                  </div>
                  <h2>Card heading</h2>
            </div>
            <div className="portfoliocard">
                  <div className="portfolioimagecard">
                        <img src="/carousel1/1.png" alt="portfolio1"/>
                  </div>
                  <h2>Card heading</h2>
            </div>
           </div> setlargepic({title:item.category_name,data:item.extra_data           <div className='d-flex justify-content-center'>
      <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="GET STARTED" iconbtn={<SiStartrek/>}/></Link>
      <Link to="/Services"><Custobtn btnvalue="Services" iconbtn={<FaBattleNet />}/></Link>
      </div>*/
 