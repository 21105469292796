import companymanagement from '../../Component/FILEJSON/applications/company management system.json'
import ecommerce from '../../Component/FILEJSON/applications/ecommerce.json'
import livechat from '../../Component/FILEJSON/applications/livechat.json'
import pos from '../../Component/FILEJSON/applications/POS.json'
import marketplace from '../../Component/FILEJSON/applications/marketplace.json'
import recruiting from '../../Component/FILEJSON/applications/recruiting portal.json'
import screenshare from '../../Component/FILEJSON/applications/screenshare.json';
import servicebooking from '../../Component/FILEJSON/applications/service booking.json';
import websitemanagementsystem from '../../Component/FILEJSON/applications/website management system.json'
import applications from '../../Component/FILEJSON/other pages and includes/applications.json'

const SolutionMenu=[ {id:1, name:'All',animationData:applications,link: '/Solutions'},
    {id:2, name:'eCommerce System',animationData:ecommerce,link: '/solutions/eCommerce_System'},
{id:3, name:'Service Booking System',animationData:servicebooking,link: '/solutions/Service_Booking_System'},
{id:4, name: 'Company Management System', animationData: companymanagement, link: '/solutions/Company_Management_System'},
{ id:5, name: 'Website Management System', animationData: websitemanagementsystem, link: '/solutions/Website_Management_System'},
{id:6, name: 'POS System', animationData: pos, link: '/solutions/POS_System'},
{id:7, name: 'Recruiting Portal', animationData: recruiting, link: '/solutions/Recruiting_Portal'},
{id:8, name: 'Marketplace', animationData: marketplace, link: '/solutions/Marketplace' },
{id:9, name: 'Live Chat, Video and Calling Web App', animationData: livechat, link: '/solutions/Live_Chat,_Video_and_Calling_Web_App'},
{id:10, name: 'Screen Sharing with Live Chat and Audio', animationData: screenshare, link: '/solutions/Screen_Sharing_with_Live_Chat_and_Audio'}]

export default SolutionMenu;