import {NavLink} from "react-router-dom";
import { BurgerBoughie } from "react-burger-icons";
import './navbar.css';
import { useState,useRef } from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Sidebar } from "./Sidebar";
import application from '../FILEJSON/other pages and includes/applications.json'
import portfolio from '../FILEJSON/portfolio/portfolio.json';
import service from '../FILEJSON/services/services.json';
import home from '../FILEJSON/home/home.json';
import getStarted from '../FILEJSON/other pages and includes/Getstarted.json'
import clientlogin from '../FILEJSON/other pages and includes/Login.json';
import Lottie from 'lottie-react';


const data = [
  { id: 1, name: "Home", moverto: "/",animationData:home },
  { id: 2, name: "Services", moverto: "/Services",animationData:service },
  { id: 3, name: "Portfolio", moverto: "/Portfolio",animationData:portfolio },
  { id: 4, name: "Solutions", moverto: "/Solutions",animationData:application },
];



export default function Navbar() {
  const [isClosed, setIsClosed] = useState(false);
  const containerRef = useRef(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [isHover,setisHover]=useState(false);
  const [isHover1,setisHover1]=useState(false);
  const [logoopen,setLogoopen]=useState(false);

  const handleMouseEnter = (id) => {
    setHoveredIcon(id);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };
const Togglelogo=()=>{
  if(!logoopen){
    setLogoopen(true)
  }else{
    setLogoopen(false)
  }
}
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-glass"
      ref={containerRef}>
      <div className="container-fluid p-0">
      <BootstrapTooltip title="menu">
           <Sidebar/>
      </BootstrapTooltip>
        <NavLink className="navbar-brand" to="/">
            <div className="logo-container" onClick={Togglelogo} onMouseEnter={()=>setLogoopen(true)} onMouseLeave={()=>setLogoopen(false)}>
              <img src="/logoicon.png" alt="logo"/>
              {logoopen===true && <h3>DA&nbsp;TECH<br/>
                <span>IDEA . </span><span>DESIGN . </span><span>CODE . </span><span>MARKETING</span>
              </h3>}
  </div>

        </NavLink>
        <div className="d-flex d-lg-none justify-content-between align-items-center">
        
      <div className='mobiledisplay'>
      <NavLink to="https://hrm.thedatech.com/client-signup" target='_blank' className="btn"><div className="d-flex">
      <Lottie
    animationData={getStarted}
    loop={!isHover}
    isStopped={!isHover}
    autoplay={isHover}
    style={{width:40,height:40}}
  /><p className="mt-2" style={{fontFamily:"CFNotche-Bold"}}>Register</p>
      </div></NavLink>
      </div>
      
      {/* Add your third button here  <button className="mx-1" id={open ? 'dotsactive' : 'dots'} onClick={menuOpen} data-bs-toggle="tooltip" data-bs-placement="bottom" title='menu'>
        <FaBuildingShield className="mb-2 mx-1" />
      </button> */}
    </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{zIndex:'1500'}}>
          <ul className="navbar-nav mx-auto text-center mb-2 mb-lg-0">
            {data &&
              data.map(({ id,animationData, name, moverto }) => (
                <BootstrapTooltip title={name}  key={id}>
                <li
                  className={`nav-item`}
                  onMouseEnter={() => handleMouseEnter(id)}
                  onMouseLeave={() => handleMouseLeave(id)}
                >
                  <NavLink
                    className="nav-link"
                    activeclassname="activelink"
                    aria-current="page"
                    to={moverto}
                  >
                 <Lottie
      animationData={animationData}
      loop={!hoveredIcon === id}
      isStopped={hoveredIcon !== id}
      autoplay={hoveredIcon === id}
      style={{ width:35, height: 40 }}
      id="screen33"
    />
                  </NavLink>
                </li>
                </BootstrapTooltip>
              ))}
          </ul>
          <div className="mobilenone">
          <div>
          
          </div>
        <NavLink to="https://hrm.thedatech.com/login" target='_blank' className="btn p-2" style={{backgroundColor:"rgba(249, 252, 253, 0.23)",color:"#fff"}} onMouseEnter={()=>setisHover1(true)}
      onMouseLeave={()=>setisHover1(false)}><div className="d-flex">
            <span><Lottie
    animationData={clientlogin}
    loop={isHover1}
    isStopped={!isHover1}
    autoplay={isHover1}
    style={{width:40,height:40}}
  /></span> <p className="mx-2 m-2" style={{fontFamily:"CFNotche-Bold"}}>Login</p>
      </div></NavLink>
              <NavLink to="https://hrm.thedatech.com/client-signup" target='_blank' className="btn m-0"  onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><div className="d-flex">
              <Lottie
    animationData={getStarted}
    loop={!isHover}
    isStopped={!isHover}
    autoplay={isHover}
    style={{width:70,height:35}}
  /> <p className="mt-2" style={{fontFamily:"CFNotche-Bold"}}>Register</p>
      </div></NavLink>
          </div>
        </div>
      </div>
    </nav>
     
  </>
  );
}
