import { Link } from "react-router-dom";
import { GetStartedbtn } from "../Application/Getstartedbtn"
import { useState } from "react";
import Lottie from 'lottie-react';
import empverification from '../../Component/FILEJSON/other pages and includes/empverification.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import axios from "axios";
import { FaCircleUser } from "react-icons/fa6";

export const EVerification=()=>{
   
  const [cnic,setcnic]=useState('');
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [empdata,setempdata]=useState();
  const handleRecordButtonClick = async(e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try{
      const getemp=await axios.get(`https://hrm.thedatech.com/api/employee/${cnic}`);
      setempdata(getemp.data);
    }catch(error){
      console.log(error)
    }
    // Your logic to handle the button click
    //const redirectUrl = `https://hrm.thedatech.com/employee/1/${cnic}`;
    //window.open(redirectUrl, '_blank');
  };
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  
    return(
      <div className="row servicemargin">
        <div className="col-md-7">
          <div className="row mx-2">
          <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
           <div className="col-11 col-md-10 d-flex justify-content-end mt-1" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><Lottie
      animationData={empverification}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 30, height: 30 }}
      id="faqiconmain"
      className='mt-1'
    /> <h1 className="subpagestitle  mx-1 mt-2">Employee Verification</h1></div>
          </div>
          <div style={{marginLeft:'1.5rem'}}>
          <p className="mx-md-5 mobilepara text-center mt-3">Want to check the employee past record before hiring?<br/>
Great! Get access to our X-Employee Record by entering the 13 digits CNIC number without dashes</p>
        <form className="mx-5 d-flex justify-content-center flex-wrap gap-1" onSubmit={handleRecordButtonClick}>
        <div className="col-md-4 offset-md-1">
        <input name="cnic" value={cnic} placeholder="4200000000000" className="verifyinput mt-2 mb-2" onChange={(e)=>setcnic(e.target.value)} required/>
        </div>
         <div className="col-md-4 mx-2"><GetStartedbtn btnvalue="Find  Record" iconbtn={empverification} type='Submit'/></div>

        
        </form>
         <div className="row justify-content-center mx-3">
          <div className="col-md-6 mt-2 desktopview">
         <p className="mb-0">Date of joining: {empdata && empdata.date_of_joining}</p>
         <p className="mb-0">Full name: {empdata && empdata.full_name}</p>
            <p className="mb-0">Designation: {empdata && empdata.designation}</p>
            <p className="mb-0">Emp ID: {empdata && empdata.emp_id}</p>
            <p className="mb-0">Department: {empdata && empdata.department}</p>
            <p className="mb-0">Job status: {empdata && empdata.job_status}</p>
            <p className="mb-0">Date of ending: {empdata && empdata.date_of_ending}</p>
          </div>
          <div className="col-md-6 mt-md-3 mb-3 desktopview">
          <div id="profilepicround">
            {empdata && empdata.profile_pic ?<img src={empdata && `https://hrm.thedatech.com${empdata.profile_pic}`} alt={empdata && empdata.full_name}/>
              :<FaCircleUser/>}
            </div>
          </div>
         </div>
         <div className="Reviewscroller">
         <div className="row justify-content-center mx-2 mobileview">
          <div className="col-md-6 mt-2">
            <div id="profilepicround">
            {empdata && empdata.profile_pic ?<img src={empdata && empdata.profile_pic} alt={empdata && empdata.full_name}/>
              :<FaCircleUser/>}
            </div>
         <p className="mb-0">Date of joining: {empdata && empdata.date_of_joining}</p>
          </div>
          <div className="col-md-6 mt-md-3 mb-3">
            <p className="mb-0">Full name: {empdata && empdata.full_name}</p>
            <p className="mb-0">Designation: {empdata && empdata.designation}</p>
            <p className="mb-0">Emp ID: {empdata && empdata.emp_id}</p>
            <p className="mb-0">Department: {empdata && empdata.department}</p>
            <p className="mb-0">Job status: {empdata && empdata.job_status}</p>
            <p className="mb-0">Date of ending: {empdata && empdata.date_of_ending}</p>
          </div>
         </div>
          <h1 className="mx-4 subpagestitle">Review:</h1>
          <p className="mx-4">{empdata && empdata.review}</p>
         </div>
          </div>
 
        </div>
        <div className="col-md-5 mt-5 desktopview">
        <div className="imgboxgif"><img src='/services/everify.gif' alt='EmployeeVerification' /></div>
        </div>
      </div>
    )
}