import './service.css';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
// import required modules
import { FreeMode,Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { HowtogetStart } from './HowtogetStart';
import Lottie from 'lottie-react';
import services from '../../Component/FILEJSON/services/services.json'
import customer from '../../Component/FILEJSON/services/custom.json'
import website from '../../Component/FILEJSON/services/website.json'
import mobileapp from '../../Component/FILEJSON/services/mobileapp.json'
import ebook from '../../Component/FILEJSON/services/ebook.json'
import googlelinkedin from '../../Component/FILEJSON/services/google linkedin youtube ads.json'
import support from '../../Component/FILEJSON/services/livechat.json'
import socialmedia from '../../Component/FILEJSON/services/smm.json'
import uiux from '../../Component/FILEJSON/services/home design.json'
import seo from '../../Component/FILEJSON/services/home marketing.json'
import maintenance from '../../Component/FILEJSON/services/support.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import eCommercemanagement from "../../Component/FILEJSON/other pages and includes/ecommercemanagement.json"
import customPatches from '../../Component/FILEJSON/other pages and includes/custompatchesServices.json';
import { useState } from 'react';
import {Helmet} from "react-helmet";


const servicesData = [
  { title: 'Custom Development', animationData: customer, link: '/services/Custom_development', description: 'Robust custom development for web applications, CRM, ERP, Portals, eCommerce, Service Booking, HRM, Live Chat Box, Website Admin Panel, POS System or any backend.' },
  { title: 'Website Design and Development', animationData: website, link: '/services/Website_design_and_development', description: 'Tailoring fully custom websites to your requirements, from UIUX mockup designs to frontend conversion, with an intuitive admin panel for seamless management on any or latest frameworks.' },
  { title: 'Application Design Development:', animationData: mobileapp, link: '/services/Mobile_app_design_and_development', description: 'UIUX mockups to functional screens, including an admin panel for effortless management. Creating fully custom mobile applications in Flutter, React Native, Ionic and Swift.' },
  { title: 'UI/UX and Graphic Designing', animationData: uiux, link: '/services/Graphic_designing', description: 'Designing pixel-perfect website and app UIUX, logos, icons, vector art, stationary, business cards, and digital collaterals - all tailored to your unique requirements.' },
  { title: 'SEO and ASO', animationData: seo, link: '/services/SEO&ASO', description: 'Mastering On-page SEO, Off-page SEO, Local SEO, Technical SEO, and Mobile App SEO. Our experts craft the perfect strategy aligned with the search engine algorithms to increase organic traffic.' },
  { title: 'Ads Management', animationData: googlelinkedin, link: '/services/Ads_Managment', description: 'Supercharge your business with targeted Google Ads and Social Media Ads Marketing, based on the targeted demographics. Elevate sales, leads, and paid traffic.' },
  { title: 'Social Media Marketing', animationData: socialmedia, link: '/services/Social_Media_Marketing', description: 'Planning, designing, and executing monthly strategies by attracting buyers directly sharing posts in groups, and creating compelling blog posts to increase organic traffic.' },
  { title: 'Book and eBook Writing', animationData: ebook, link: '/services/Book_&_eBook_Writing', description: 'Premium eBook writing services for Authors. Our expert writers turn your vision into a captivating book that elevates your brand.' },
  { title: 'Maintenance and Support', animationData: maintenance, link: '/services/Maintenance_and_support', description: 'Providing ongoing maintenance and support services to keep your website or mobile application up to date, live, working perfectly and running.' },
  { title: 'Live Chat Support and Sales', animationData: support, link: '/services/Sales_and_support', description: 'Your 24/7 Front Desk Officers - providing customer support to your traffic and engage them to generate sales and offer support based on the SOP’s.' },
  {title:'Custom Patches and Metal Badges for Apparels', animationData: customPatches, link:'/services/Custom_patches',description:'Order custom patches, metal badges and custom apparels today. We offer a wide range of patches, badges and apparel options to meet your specific needs.'},
  {title:'eCommerce Management', animationData: eCommercemanagement, link:'/services/eCommerce_management',description:'Our eCommerce Management Services include anything from Amazon Seller Accounts to Shopify Store Management. Let us handle your eCommerce operations on Amazon, eBay, Etsy, Walmart, and Shopify.'}
];

const servicesmenu = [
  {id:1, name:'Services',animationData:services,link: '/services'},
  {id:2, name:'Custom Development',animationData:customer,link: '/services/Custom_development'},
  {id:3, name: 'Website Design and Development', animationData: website, link: '/services/Website_design_and_development'},
  { id:4, name: 'Application Design Development', animationData: mobileapp, link: '/services/Mobile_app_design_and_development'},
  {id:5, name: 'UI/UX and Graphic Designing', animationData: uiux, link: '/services/Graphic_designing'},
  {id:6, name: 'SEO and ASO', animationData: seo, link: '/services/SEO&ASO'},
  {id:7, name: 'Ads Management', animationData: googlelinkedin, link: '/services/Ads_Managment' },
  {id:8, name: 'Social Media Marketing', animationData: socialmedia, link: '/services/Social_Media_Marketing'},
  {id:9, name: 'Book and eBook Writing', animationData: ebook, link: '/services/Book_&_eBook_Writing'},
  {id:10, name: 'Maintenance and Support', animationData: maintenance, link: '/services/Maintenance_and_support' },
  {id:11, name: 'Live Chat Support and Sales', animationData: support, link: '/services/Sales_and_support' },
  {id:12, name:'Custom Patches and Metal Badges for Apparels',animationData: customPatches, link:'/services/Custom_patches'},
  {id:13, name:'eCommerce Management',animationData: eCommercemanagement, link:'/services/eCommerce_management'}
]

function Services() {
  const [isback,setisback]=useState(false);
const [hoverStates, setHoverStates] = useState(Array(servicesData.length).fill(false));
const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

const handleMouseMove = (e) => {
  setMousePosition({
    x: e.clientX,
    y: e.clientY,
  });
};

  const handleMouseEnter = (index) => setHoverStates(hoverStates.map((state, i) => (i === index ? true : state)));
  const handleMouseLeave = () => setHoverStates(Array(servicesData.length).fill(false));

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id)
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.black,
    },
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '14px',
    },
  }));
    return(
      <div className='row servicemargin'>
          <Helmet>
        <title>Hire an Award-winning IT company | Design, Development, Digital Marketing and Business Solutions |
        DA Tech</title>
        <meta name="description" content="Award-Winning IT Company: Design, Development, Digital Marketing and Business Solutions provider
near me. From creative design to robust developments and marketing with 24/7 live chat support, we
can handle everything." />
        <meta name='keywords' content="award-winning it company near me, development, design, digital marketing, business solutions"/>
     </Helmet>
        <div className="col-12">
        <div className="btnsergridcontainer mx-3">
          <div className="col-1" onMouseMove={handleMouseMove}  onMouseEnter={() => setisback(true)}
        onMouseLeave={() => setisback(false)}>
          <BootstrapTooltip title="Previous Page" placement="bottom"
       PopperProps={{
        anchorEl: {
          getBoundingClientRect: () => ({
            top: mousePosition.y + 10, // Adjust tooltip position below the mouse
            left: mousePosition.x - 40, // Adjust for horizontal centering
            width: 0,
            height: 0,
          }),
        },
      }}
  >
              <Link to='/'><Lottie
      animationData={back}
      loop={isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip>
          {/* <BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip> */}
          </div>
         <div className='col-11'>
         <h1  className='text-center subpagestitle mb-0'>Services</h1>
          <p className='text-center mb-0 paragraphmargin' style={{fontFamily:"CFNotche-Bold"}}>Hire an Award-winning IT company for design, custom development, digital marketing and business
solutions. Sign up now and effortlessly add your project in our advanced Project Management App.</p>
         </div>
          </div>
          <div className='btnsergridcontainer '>
          <Swiper slidesPerView={11}
        spaceBetween={10}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 11,
            spaceBetween: 10,
          },
        }}>
          {servicesmenu.map((menu)=>{
             return(
              <SwiperSlide className="swiperfaqbgt" key={menu.id}>
              <BootstrapTooltip title={menu.name} onMouseEnter={() => handleMouseEnter1(menu.id)}
              onMouseLeave={() => handleMouseLeave1(menu.id)} key={menu.id}>
                      <Link to={menu.link} style={{color:'#ff6600'}}>
                      <Lottie
              animationData={menu.animationData}
              loop={hoverStatesmenu===menu.id}
              isStopped={!hoverStatesmenu!==menu.id}
              autoplay={hoverStatesmenu===menu.id}
              id="servicemenuciricon"
            /></Link></BootstrapTooltip>
            </SwiperSlide>
             )
            })}
        </Swiper>
          </div>
         <div className='row mt-1 d-flex'>
         <Swiper
        slidesPerView={1}
        spaceBetween={10}
        freeMode={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        modules={[FreeMode,Autoplay]}
        className="mySwiper p-2"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          }
        }}
      >
      {servicesData.map((service, index) => (
            <SwiperSlide key={index}>
              <Link to={service.link}>
                <div className='Servicecard mx-auto' onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={()=>handleMouseLeave(index)}>
                  <Lottie
                    animationData={service.animationData}
                    loop={hoverStates[index]}
                    isStopped={!hoverStates[index]}
                    autoplay={hoverStates[index]}
                    style={{ width: 40, height: 40 }}
                    className='mt-1 mb-md-1 servicecardbox_icon'
                  />
                  <h5>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        
      </Swiper>
         </div>
         <HowtogetStart/>
        </div>
      </div>
    )
    }
    
    export default Services;