import './App.css';
import { Route,Routes,useLocation } from 'react-router-dom';
import Home from "./Pages/Home/Home";
import About from './Pages/About/About';
import Blogs from './Pages/Blogs/Blogs';
import {Contact} from './Pages/Contact/Contact';
import Portfolio from './Pages/Portfolio/Portfolio';
import Services from './Pages/Services/Services';
import { ServiceDetail } from './Pages/Services/ServiceDetail';
import {Career} from './Pages/Career/Career';
import { FAQ } from './Pages/FAQ/FAQ';
import { Testimonials } from './Pages/Testimonials/Testimonials';
import { Application } from './Pages/Application/Application';
import { EVerification } from './Pages/EVerification/EVerification';
import { BlogDetail } from './Pages/Blogs/BlogDetail';
import { TermsCondition } from './Pages/TermsCondition/TermsCondition';
import { SolutionDetail } from './Pages/Application/SolutionDetail';
import { PortfolioForm } from './Pages/PortfolioForm';

function App() {
  const location=useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home/>}/>
      <Route path="/Services" element={<Services/>}/>
      <Route path="/Portfolio" element={<Portfolio/>}/>
      <Route path="/About" element={<About/>}/>
      <Route path="/Blogs" element={<Blogs/>}/>
      <Route path="/Contact" element={<Contact/>}/>
      <Route path="/services/:name" element={<ServiceDetail/>}/>
      <Route path="/privacypolicy" element={<Career/>}/>
      <Route path="/FAQ" element={<FAQ/>}/>
      <Route path="/FAQ/:slug" element={<FAQ/>}/>
      <Route path='/Testimonials' element={<Testimonials/>}/>
      <Route path='/Solutions' element={<Application/>}/>
      <Route path='/EVerification' element={<EVerification/>}/>
      <Route path='/terms&condition' element={<TermsCondition/>}/>
      <Route path="/Blogs/:title" element={<BlogDetail/>}/>
      <Route path="/solutions/:name" element={<SolutionDetail/>}/>
      <Route path="/form" element={<PortfolioForm/>}/>
    </Routes>
  );
}

export default App;
