import Lottie from 'lottie-react';

import { useState } from 'react';
export const GetStartedbtn=({btnvalue,iconbtn})=>{
    const [isHover,setisHover]=useState(false);
    return(
       <button className="launchbtn mx-auto" onMouseEnter={()=>setisHover(true)}
       onMouseLeave={()=>setisHover(false)}>
        <div className="launchbtnwrapper1">
            <div className="gvzymi"><svg id="hhhhhheight" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26.5" stroke="url(#paint0_angular)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="3 20 8 1 10 8"></circle><defs><radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.5 27.5) rotate(107.904) scale(34.1541)"><stop offset="0.0870822" stopColor="#000000"></stop><stop offset="0.69191" stopColor="#000000"></stop></radialGradient></defs></svg></div>
        </div>
        <div className="kRiXyW">
        <Lottie
      animationData={iconbtn}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
    />
        </div>
        <div className="cbypVx">{btnvalue}</div></button>
    )
}