import "./faq.css";
import { FAQBOX } from "./FAQBOX";
import { Link, useParams } from 'react-router-dom'; 
import { Custobtn } from "../Application/Custobtn";
import { GetStartedbtn } from "../Application/Getstartedbtn";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from "react";
import Lottie from 'lottie-react';
import faq from '../../Component/FILEJSON/other pages and includes/faq.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import customer from '../../Component/FILEJSON/services/custom.json'
import website from '../../Component/FILEJSON/services/website.json'
import mobileapp from '../../Component/FILEJSON/services/mobileapp.json'
import ebook from '../../Component/FILEJSON/services/ebook.json'
import googlelinkedin from '../../Component/FILEJSON/services/google linkedin youtube ads.json'
import support from '../../Component/FILEJSON/services/livechat.json'
import socialmedia from '../../Component/FILEJSON/services/smm.json'
import uiux from '../../Component/FILEJSON/services/home design.json'
import seo from '../../Component/FILEJSON/services/home marketing.json'
import maintenance from '../../Component/FILEJSON/services/support.json'
import getStarted from '../../Component/FILEJSON/other pages and includes/Getstarted.json';
import contact from '../../Component/FILEJSON/other pages and includes/Contactpage.json';
import eCommerce from '../../Component/FILEJSON/other pages and includes/ecommercemanagement.json';
import customPatches from '../../Component/FILEJSON/other pages and includes/custompatchesServices.json';
import {Helmet} from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const servicesmenu = [
  {id:1, name:'All',animationData:faq},
  {id:2, name:'Custom Development',animationData:customer,link:"custom_development"},
  {id:3, name: 'Website Development', animationData: website,link:"Website_design_and_development"},
  { id:4, name: 'Mobile Application Development', animationData: mobileapp,link:"Mobile_app_design_and_development"},
  {id:5, name: 'UI/UX and Graphic Designing', animationData: uiux,link:"UIUX_and_graphic_designing"},
  {id:6, name: 'SEO and ASO', animationData: seo,link:"SEO_and_ASO"},
  {id:7, name: 'Ads Management', animationData: googlelinkedin,link:"Ads_Managment"},
  {id:8, name: 'Social Media Marketing', animationData: socialmedia,link:"Social_media_marketing"},
  {id:9, name: 'Book and eBook Writing', animationData: ebook,link:"Book_and_eBook_writing"},
  {id:10, name: 'Maintenance and Support', animationData: maintenance,link:"Maintenance_and_support"},
  {id:11, name: 'Live Chat Support and Sales', animationData: support,link:"Live_chat_support_and_sales"},
  {id:12, name: 'Custom Patches and Metal Badges for Apparels', animationData: customPatches,link:"Custom_patches_and_metal_badges_for_apparels"},
  {id:13, name: 'eCommerce Management', animationData: eCommerce,link:"eCommerce_management"}
]
const data1=[{
    id:1,
    slug:"custom_development",
    categoryname:"Custom Development",
    question:"Q1: What types of custom applications can DA Tech design and develop?",
    answer:"A: DA Tech specializes in crafting robust custom applications, including CRM, ERP, Custom Portals, eCommerce platforms, Service Booking systems, HRM Systems, Live Chat Boxes, Website Admin Panels, and POS Systems." 
  },
   {
    id:2,
    slug:"custom_development",
    categoryname:"Custom Development",
    question:"Q2:  How does the custom development process work at DA Tech?",
    answer:"A: The custom development process at DA Tech involves a detailed consultation to understand your unique requirements, followed by the design, development, and testing phases, ensuring a tailored solution that meets your business needs."
   },
   {
    id:3,
    slug:"custom_development",
    categoryname:"Custom Development",
    question:"Q3: Can DA Tech integrate third-party APIs into custom applications?",
    answer:"A: Absolutely! DA Tech has expertise in seamlessly integrating third-party APIs to enhance the functionality and features of your custom applications."
   },
   {
    id:4,
    slug:"custom_development",
    categoryname:"Custom Development",
    question:"Q4: What security measures does DA Tech implement in custom development?",
    answer:"A: DA Tech prioritizes security and employs encryption, authentication, and authorization protocols to safeguard data and ensure the integrity of custom applications."
   },
   {
    id:5,
    slug:"custom_development",
    categoryname:"Custom Development",
    question:"Q5: Does DA Tech provide ongoing support for custom applications post-launch?",
    answer:"A: Yes, DA Tech offers comprehensive support and maintenance services to address any issues, implement updates, and ensure the optimal performance of custom applications."
   },
{
   id:6,
   slug:"Website_design_and_development",
   categoryname:"Website Development",
   question:"Q1: What is the approach to designing and developing custom websites at DA Tech?",
   answer:"A: DA Tech takes a comprehensive approach, starting with UIUX mockup designs, converting them into a frontend, and integrating an intuitive admin panel for seamless website management."
  },
  {
   id:7,
   slug:"Website_design_and_development",
   categoryname:"Website Development",
   question:"Q2: Can DA Tech redesign an existing website?",
   answer:"A: Absolutely! DA Tech offers website redesign services to enhance the visual appeal, user experience, and functionality of your existing website."
  },
  {
   id:8,
   slug:"Website_design_and_development",
   categoryname:"Website Development",
   question:"Q3: What technologies does DA Tech utilize for responsive website design?",
   answer:"A: DA Tech employs cutting-edge technologies like Bootstrap and CSS frameworks to ensure responsive and visually appealing designs for websites."
  },
  {
   id:9,
   slug:"Website_design_and_development",
   categoryname:"Website Development",
   question:"Q4: How does DA Tech optimize websites for search engines during development?",
   answer:"A: DA Tech follows SEO best practices, optimizing website structures, content, and meta tags during development to enhance search engine visibility."
  },
  {
   id:10,
   slug:"Website_design_and_development",
   categoryname:"Website Development",
   question:"Q5: Can DA Tech integrate e-commerce functionality into websites?",
   answer:"A: Yes, DA Tech specializes in integrating robust e-commerce features, providing seamless online shopping experiences for your customers."
  },
{
  id:11,
  slug:"Mobile_app_design_and_development",
  categoryname:"Mobile Application Development",
  question:"Q1: In which technologies does DA Tech specialize for mobile app development?",
  answer:"A: DA Tech excels in mobile app development using Flutter, Ionic, Swift, React Native, Kotlin, Java, Xarmin, and PhoneGap for both Android and iOS platforms."
 },
 {
  id:12,
  slug:"Mobile_app_design_and_development",
  categoryname:"Mobile Application Development",
  question:"Q2: What is the process for developing a custom mobile application at DA Tech?",
  answer:"A: The process includes translating UIUX mockup designs into functional screens, integrating an admin panel for management, and thorough testing to ensure a seamless mobile app experience."
 },
 {
  id:13,
  slug:"Mobile_app_design_and_development",
  categoryname:"Mobile Application Development",
  question:"Q3: Can DA Tech optimize mobile apps for different screen sizes and resolutions?",
  answer:"A: Absolutely! DA Tech ensures that mobile apps are optimized for various screen sizes and resolutions to provide a consistent and user-friendly experience."
 },
 {
  id:14,
  slug:"Mobile_app_design_and_development",
  categoryname:"Mobile Application Development",
  question:"Q4: Does DA Tech provide cross-platform mobile app development services?",
  answer:"A: Yes, DA Tech offers cross-platform development, allowing apps to run on both Android and iOS devices, minimizing development time and costs."
 },
 {
  id:15,
  slug:"Mobile_app_design_and_development",
  categoryname:"Mobile Application Development",
  question:"Q5: What measures does DA Tech take to ensure mobile app security?",
  answer:"A: DA Tech implements secure coding practices and conducts thorough security audits to protect mobile apps from potential vulnerabilities and unauthorized access."
 },
{
  id:16,
  slug:"UIUX_and_graphic_designing",
  categoryname:"UI/UX and Graphic Designing",
  question:"Q1: What design services does DA Tech offer?",
  answer:"A: DA Tech provides pixel-perfect design services, covering Logo design, Web and Mobile UIUX, Images, Icons, Stationary, Business Card design, and digitizing collaterals."
 },
 {
  id:17,
  slug:"UIUX_and_graphic_designing",
  categoryname:"UI/UX and Graphic Designing",
  question:"Q2: Can DA Tech create a brand identity for my business?",
  answer:"A: Absolutely! DA Tech specializes in creating a cohesive brand identity through visually appealing and consistent design elements."
 },
 {
  id:18,
  slug:"UIUX_and_graphic_designing",
  categoryname:"UI/UX and Graphic Designing",
  question:"Q3: How does DA Tech ensure a user-friendly design in UIUX projects?",
  answer:"A: DA Tech follows industry best practices, conducts user research, and employs intuitive design principles to ensure a user-friendly experience, enhancing overall user satisfaction."
 },
 {
  id:19,
  slug:"UIUX_and_graphic_designing",
  categoryname:"UI/UX and Graphic Designing",
  question:"Q4: Can DA Tech redesign a logo while retaining the essence of our brand?",
  answer:"A: Certainly! DA Tech specializes in logo redesign, preserving brand identity elements while giving your logo a fresh and modern look."
 },
 {
  id:20,
  slug:"UIUX_and_graphic_designing",
  categoryname:"UI/UX and Graphic Designing",
  question:"Q5: Can DA Tech create custom icons and images for my website or app?",
  answer:"A: Absolutely! DA Tech provides bespoke design services, including custom icons and images, ensuring a unique and visually appealing representation for your brand."
 },
 {
  id:21,
  slug:"SEO_and_ASO",
  categoryname:"SEO and ASO",
  question:"Q1: What SEO services does DA Tech offer?",
  answer:"A: DA Tech offers a range of SEO services, including On-page SEO, Off-page SEO, Local SEO, Technical SEO, and Mobile App SEO to enhance your online visibility and ranking."
},
 {
  id:22,
  slug:"SEO_and_ASO",
  categoryname:"SEO and ASO",
  question:"Q2: How long does it take for SEO efforts to show results with DA Tech?",
  answer:"A: SEO timelines vary, but DA Tech implements strategic and ethical practices to enhance your online visibility. Results typically become noticeable within a few months."
 },
 {
  id:23,
  slug:"SEO_and_ASO",
  categoryname:"SEO and ASO",
  question:"Q3: Can DA Tech provide a comprehensive SEO audit for my website?",
  answer:"A: Yes, DA Tech conducts in-depth SEO audits, identifying areas for improvement in on-page, off-page, and technical aspects to boost your website's search engine ranking."
 },
 {
  id:24,
  slug:"SEO_and_ASO",
  categoryname:"SEO and ASO",
  question:"Q4: What ASO strategies does DA Tech employ to improve app visibility?",
  answer:"A: DA Tech focuses on optimizing app titles, descriptions, keywords, and visuals, ensuring your mobile app ranks higher and attracts more downloads on app stores."
 },
 {
  id:25,
  slug:"SEO_and_ASO",
  categoryname:"SEO and ASO",
  question:"Q5: How does DA Tech stay updated with search engine algorithm changes?",
  answer:"A: DA Tech's SEO team constantly monitors industry trends, attends conferences, and participates in continuous learning to adapt to search engine algorithm changes effectively."
 },
 {
  id:26,
  slug:"Ads_management",
  categoryname:"Ads Management",
  question:"Q1: How can DA Tech boost my business with Google Ads?",
  answer:"A: DA Tech crafts targeted Google Ads campaigns tailored to your business niche, driving increased traffic, leads, and sales."
 },
 {
  id:27,
  slug:"Ads_management",
  categoryname:"Ads Management",
  question:"Q2: What platforms does DA Tech manage ads on?",
  answer:"A: DA Tech manages ads on various platforms, including Google Ads, YouTube Ads, and LinkedIn Ads, ensuring a strategic and impactful online presence."
 },
 {
  id:28,
  slug:"Ads_management",
  categoryname:"Ads Management",
  question:"Q3: How does DA Tech determine the target audience for Google Ads campaigns?",
  answer:"A: DA Tech conducts thorough market research to identify your target audience, refining Google Ads campaigns to reach the right demographic and increase conversion rates."
 },
 {
  id:29,
  slug:"Ads_management",
  categoryname:"Ads Management",
  question:"Q4: Can DA Tech help in creating engaging content for ads?",
  answer:"A: Certainly! DA Tech provides content creation services, crafting compelling ad copy and visuals that resonate with your audience and drive engagement."
 },
 {
  id:30,
  slug:"Ads_management",
  categoryname:"Ads Management",
  question:"Q5: What metrics does DA Tech track to measure the success of ads?",
  answer:"A: DA Tech tracks key metrics such as click-through rates, conversion rates, and return on investment (ROI) to assess the effectiveness of ads and optimize future campaigns."
 },
{
  id:31,
  slug:"Social_media_marketing",
  categoryname:"Social Media Marketing",
  question:"Q1: What social media services does DA Tech offer?",
  answer:"A: DA Tech provides comprehensive Social Media Marketing services, including strategy planning, post design, group engagement, and blog post scheduling for weekly and monthly activities."
 },
 {
  id:32,
  slug:"Social_media_marketing",
  categoryname:"Social Media Marketing",
  question:"Q2: Can DA Tech help in building my brand on social media?",
  answer:"A: Absolutely! DA Tech excels in building and enhancing brand presence on social media platforms, attracting your target audience and increasing engagement."
 },
 {
  id:33,
  slug:"Social_media_marketing",
  categoryname:"Social Media Marketing",
  question:"Q3: Does DA Tech create and manage social media advertising campaigns?",
  answer:"A: Yes, DA Tech develops and manages targeted social media advertising campaigns to promote your brand, products, and services to a wider audience."
 },
 {
  id:34,
  slug:"Social_media_marketing",
  categoryname:"Social Media Marketing",
  question:"Q4: What social media platforms does DA Tech specialize in?",
  answer:"A: DA Tech specializes in various platforms, including but not limited to Facebook, Instagram, Twitter, LinkedIn, and Pinterest, tailoring strategies to each platform's unique audience."
 },
 {
  id:35,
  slug:"Social_media_marketing",
  categoryname:"Social Media Marketing",
  question:"Q5: How does DA Tech measure the success of social media campaigns?",
  answer:"A: DA Tech uses analytics tools to track engagement, reach, and conversions, providing detailed reports to measure the success of social media campaigns and refine strategies accordingly."
 },
{
  id:36,
  slug:"Book_and_eBook_writing",
  categoryname:"Book and eBook Writing",
  question:"Q1: How does DA Tech's eBook writing service work?",
  answer:"A: DA Tech's eBook writing service involves expert writers turning your vision into compelling stories that captivate readers, elevating your brand and enhancing your online presence."
 },
 {
  id:37,
  slug:"Book_and_eBook_writing",
  categoryname:"Book and eBook Writing",
  question:"Q2: Can DA Tech help me publish my book or eBook?",
  answer:"A: Yes, DA Tech can assist in the publishing process, ensuring your book or eBook reaches a wider audience through various online platforms."
 },
 {
  id:38,
  slug:"Book_and_eBook_writing",
  categoryname:"Book and eBook Writing",
  question:"Q3: What genres does DA Tech cover in eBook writing?",
  answer:"A: DA Tech covers a wide range of genres, including business, fiction, non-fiction, self-help, technology, and more, ensuring diverse and captivating content for your audience."
 },
 {
  id:39,
  slug:"Book_and_eBook_writing",
  categoryname:"Book and eBook Writing",
  question:"Q4: Does DA Tech provide ghostwriting services for books?",
  answer:"A: Absolutely! DA Tech offers ghostwriting services, allowing you to publish books under your name while benefiting from professional writing expertise."
 },
 {
  id:40,
  slug:"Book_and_eBook_writing",
  categoryname:"Book and eBook Writing",
  question:"Q5: Can DA Tech assist in designing book covers and formatting eBooks?",
  answer:"A: Yes, DA Tech provides comprehensive services, including designing eye-catching book covers and formatting eBooks for various platforms, enhancing the overall appeal of your publications."
 },
{
  id:41,
  slug:"Maintenance_and_support",
  categoryname:"Maintenance and Support",
  question:"Q1: What ongoing maintenance services does DA Tech provide?",
  answer:"A: DA Tech offers ongoing maintenance and support services to keep your website or mobile application up-to-date, running smoothly, and addressing any issues promptly."
 },
 {
  id:42,
  slug:"Maintenance_and_support",
  categoryname:"Maintenance and Support",
  question:"Q2: How can I avail DA Tech's support services?",
  answer:"A: You can avail DA Tech's support services by reaching out through our contact form or support channels. Our team will promptly assist with any queries or issues."
 },
 {
  id:43,
  slug:"Maintenance_and_support",
  categoryname:"Maintenance and Support",
  question:"Q3: Does DA Tech offer emergency support for critical issues?",
  answer:"A: Yes, DA Tech provides emergency support for critical issues, ensuring quick resolution and minimal downtime for your digital assets."
 },
 {
  id:44,
  slug:"Maintenance_and_support",
  categoryname:"Maintenance and Support",
  question:"Q4: Can DA Tech assist with website or app updates and feature additions?",
  answer:"A: Certainly! DA Tech helps with regular updates, feature additions, and improvements to ensure your website or app stays current and competitive."
 },
 {
  id:45,
  slug:"Maintenance_and_support",
  categoryname:"Maintenance and Support",
  question:"Q5: What security measures does DA Tech implement for website and app maintenance?",
  answer:"A: DA Tech employs security patches, regular backups, and monitoring protocols to ensure the ongoing security of your website or mobile application during maintenance."
 },
 {
  id:51,
  slug:"Live_chat_support_and_sales",
  categoryname:"Live Chat Support and Sales",
  question:"Q1: How does DA Tech's LiveChat Support and Sales service benefit my business?",
  answer:"A: DA Tech's LiveChat service provides 24/7 support to engage with website visitors, answer queries, and facilitate sales, contributing to a proactive customer support approach and lead generation."
 },
 {
  id:52,
  slug:"Live_chat_support_and_sales",
  categoryname:"Live Chat Support and Sales",
  question:"Q2: Can DA Tech tailor LiveChat scripts to my business needs?",
  answer:"A: Absolutely! DA Tech customizes LiveChat scripts to align with your business needs, ensuring personalized interactions with visitors and potential customers."
 },
 {
  id:53,
  slug:"Live_chat_support_and_sales",
  categoryname:"Live Chat Support and Sales",
  question:"Q3: Does DA Tech integrate LiveChat with CRM systems for lead management?",
  answer:"A: Yes, DA Tech integrates LiveChat with CRM systems, enabling efficient lead management, tracking, and follow-up for enhanced customer relationship management."
 },
 {
  id:54,
  slug:"Live_chat_support_and_sales",
  categoryname:"Live Chat Support and Sales",
  question:"Q4: Can DA Tech provide multilingual LiveChat support for a global audience?",
  answer:"A: Certainly! DA Tech offers multilingual LiveChat support, allowing you to connect with a diverse audience and expand your reach globally."
 },
 {
  id:55,
  slug:"Live_chat_support_and_sales",
  categoryname:"Live Chat Support and Sales",
  question:"Q5: How does DA Tech ensure data privacy and security in LiveChat interactions?",
  answer:"A: DA Tech prioritizes data privacy and security, employing encryption and secure protocols to safeguard customer information during Live Chat interactions."
 },
 {
  id:56,
  slug:"Custom_patches_and_metal_badges_for_apparels",
  categoryname:"Custom Patches and Metal Badges for Apparels",
  question:"Q1: Do you accept small orders, or only bulk quantities for custom patches and metal badges?",
  answer:"A: Yes, we welcome both small and bulk orders. Whether it is just one custom patch, or several hundred badges - our flexible production capabilities enable us to meet any order size while upholding high quality standards in each piece produced."
 },
 {
  id:57,
  slug:"Custom_patches_and_metal_badges_for_apparels",
  categoryname:"Custom Patches and Metal Badges for Apparels",
  question:"Q2: What custom apparel accessories do you make?",
  answer:"A: We manufacture an extensive variety of custom apparel accessories, including custom shirts, t-shirts, hoodies, caps, beanies, zippers, patches metal badges buttons and zipper pulls. Whatever it may be that's needed to complement your apparel design and branding goals we can manufacture it specifically to match."
 },
 {
  id:58,
  slug:"Custom_patches_and_metal_badges_for_apparels",
  categoryname:"Custom Patches and Metal Badges for Apparels",
  question:"Q3: Can I customize both the design and material of patches and badges?",
  answer:"A: Absolutely! Our services allow for full customization for both design and material of patches and badges, from embroidered, woven, PVC patches or metal badges made of brass, aluminum or other materials - each detail aligning perfectly with your vision. You can even choose different finishes, colors and backing options to find that perfect piece!"
 },
 {
  id:59,
  slug:"Custom_patches_and_metal_badges_for_apparels",
  categoryname:"Custom Patches and Metal Badges for Apparels",
  question:"Q4: What is the production timeframe for custom patches and metal badges?",
  answer:"A: Production timeframes depend on order size and complexity. Small orders usually take 7-10 business days for production while bulk orders may require additional time - we strive to meet deadlines without compromising on quality; once your details have been confirmed we'll provide an accurate timeline."
 },
 {
  id:60,
  slug:"Custom_patches_and_metal_badges_for_apparels",
  categoryname:"Custom Patches and Metal Badges for Apparels",
  question:"Q5: Do You Ship Custom Apparel Accessories Internationally?",
  answer:"A: Yes, we deliver custom patches, metal badges and other apparel accessories worldwide. Our primary export markets are the USA, Canada, Europe, UAE Australia New Zealand; however, we can accommodate customers from other regions as well. You can trust in safe and timely delivery for all international orders with us!"
 },
 {
   id:61,
   slug:"eCommerce_management",
   categoryname:"eCommerce Management",
  question:"Q1: What does your eCommerce Management service include?",
  answer:"A: Our eCommerce Management service offers a complete solution for managing online stores across various platforms like Amazon, eBay, Walmart, Etsy, and Shopify. We handle everything from product listing optimization, inventory management, order fulfillment, customer service, and account health monitoring. Additionally, we provide specialized services such as Amazon PPC campaigns, eCommerce SEO, and platform-specific account management to boost sales and improve the overall performance of your online store."
 },
 {
  id:62,
  slug:"eCommerce_management",
  categoryname:"eCommerce Management",
 question:"Q2: How can your eCommerce Management services improve my sales?",
 answer:"A: We focus on several key areas to drive growth and increase sales for your eCommerce business: • Optimized Product Listings: We ensure your products are SEO-optimized with the right keywords, images, and descriptions for better visibility in search results. • PPC Advertising: Our team runs data-driven Amazon PPC and other ad campaigns to bring more traffic to your store. • Customer Service & Reviews: By managing customer queries and ensuring positive interactions, we help maintain good seller ratings, which directly impact sales. • Account Health Monitoring: We keep your account compliant with platform policies and avoid issues like suspensions, so your store runs smoothly without disruptions."
},
{
  id:63,
  slug:"eCommerce_management",
  categoryname:"eCommerce Management",
 question:"Q3: How do you manage multiple platforms like Amazon, eBay, Walmart, and Etsy?",
 answer:"A: We use a customized approach for each platform, ensuring that your store adheres to specific marketplace requirements and best practices. For example: • Amazon Seller Account Management: Includes optimizing product pages, monitoring seller account health, and managing Amazon PPC ads. • eBay Account Management: Focuses on listing optimization, handling customer inquiries, and ensuring eBay policies compliance. • Walmart & Etsy Account Management: We ensure product listings are well-optimized, compliant, and visible to potential customers. We also assist with strategies like improving product photography and keyword usage, tailored to the platform's unique audiences. Our team is experienced in managing multiple marketplaces simultaneously, ensuring smooth operations and growth across all channels."
},
{
  id:64,
  slug:"eCommerce_management",
  categoryname:"eCommerce Management",
 question:"Q4: How does your eCommerce Store SEO service work?",
 answer:"A: Our eCommerce Store SEO service is designed to improve your online store's search rankings on platforms like Amazon, Shopify, eBay, and more. We conduct: • Keyword Research: To identify high-traffic and relevant keywords for your products. • Competitor Analysis: We analyze what competitors are doing and ensure your listings stand out with optimized descriptions, titles, and tags. • On-Page Optimization: Our team optimizes each product page with rich, relevant content to improve visibility in both platform and external search engines like Google. • Content and Image Optimization: We ensure all product images and content adhere to SEO best practices for higher search rankings. Our goal is to drive more organic traffic to your store, resulting in increased visibility and sales."
},
{
  id:65,
  slug:"eCommerce_management",
  categoryname:"eCommerce Management",
 question:"Q5: How do you ensure my eCommerce accounts remain healthy and compliant with platform policies?",
 answer:"A: We closely monitor all accounts and platforms to ensure compliance with each platform’s specific guidelines: • Account Health Monitoring: We constantly track your Amazon Seller Account Health, ensuring you're within performance metrics like order defect rate, valid tracking rate, and feedback score. • Policy Compliance: We make sure that your store is aligned with the latest policies of each platform to prevent account suspensions or penalties. • Reinstatement Support: In case of any issues such as ASIN or Account Suspensions, our team offers Amazon Seller Account Reinstatement and ASIN Reinstatement services to get your business back on track as quickly as possible. By ensuring your accounts stay healthy and compliant, we help you avoid downtime and potential loss of sales."
}
]
export const FAQ=()=>{
  const [expanded, setExpanded] = useState(false);
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [filteredData, setFilteredData] = useState(data1);
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
  const {slug} = useParams();

  console.log('slug',slug);

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id)
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // const filterByCategory = (categoryName) => {
  
  //   if (categoryName === 'All') {
  //     setFilteredData(data1);
  //   } else {
  //     const filteredQuestions = data1.filter(item => item.categoryname === categoryName);
  //     setExpanded([]);
  //     setFilteredData(filteredQuestions);
  //   }
  // };

 
  // Filter FAQ based on the category or slug
  const filterByCategory = (slug) => {
    if (!slug || slug.toLowerCase() === 'all') {
      // Show all FAQ data if no slug or "All" is selected
      setFilteredData(data1);
    } else {
      // Otherwise, filter by categoryname or slug
      const filteredQuestions = data1.filter(item =>
        item.categoryname.toLowerCase() === slug.toLowerCase() || item.slug?.toLowerCase() === slug.toLowerCase()
      );
      setFilteredData(filteredQuestions);
    }
    setExpanded([]); // Collapse all previously expanded items
  };

  // Automatically filter data by slug when component mounts or slug changes
  useEffect(() => {
    filterByCategory(slug);
  }, [slug]);

  let prevCategory = null;



    return(
        <div className="servicemargin">
           <Helmet>
        <title>Digital Services based answers to your questions on design, development, digital marketing, business
        solutions and more</title>
        <meta name="description" content="Latest insights on design, development, digital marketing, business solutions and services. Get the
information you need to elevate your digital journey with us." />
        <meta name="keywords" content="design, development, digital marketing, business website, mobile application, paid ads, build a website,
uiux designing, live chat support and sales, grow a business, get rankings, get famous, digital services"/>
     </Helmet>
            <div className='row mx-2'>
            <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-11 d-flex justify-content-md-center justify-content-end"><h1 className="subpagestitle mx-1 mb-0 mt-3 mt-sm-2">FAQ’s</h1></div>
           </div>
           <div className="btnsergridcontainer mt-2 mb-2">
              <div className="mx-md-3"></div>
              <Swiper slidesPerView={11}
        spaceBetween={10}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 11,
            spaceBetween: 10,
          },
        }}>
              {servicesmenu.map((menu)=>{
             return(
              <SwiperSlide className="swiperfaqbgt" key={menu.id}>
              <BootstrapTooltip title={menu.name} onClick={() => filterByCategory(menu.name)} onMouseEnter={() => handleMouseEnter1(menu.id)}
              onMouseLeave={() => handleMouseLeave1(menu.id)}>
                      <Link to={`/FAQ/${menu.link}`} style={{color:'#ff6600'}}>
                      <Lottie
              animationData={menu.animationData}
              loop={!hoverStatesmenu===menu.id}
              isStopped={!hoverStatesmenu!==menu.id}
              autoplay={hoverStatesmenu===menu.id}
              id="servicemenuciricon"
            /></Link></BootstrapTooltip></SwiperSlide>
             )
            })}
              </Swiper>
          </div>
          <div className="row">
            <div className="col-md-7">
            <div className="faqboxcontainer mx-3">
            {filteredData && filteredData.map(({ id, question, answer,categoryname }) => {
           const showCategory = categoryname !== prevCategory;
           prevCategory = categoryname;
              return(
              <>
              {showCategory && <h1 className='text-center' style={{color:'rgba(254,136,0,255)'}}>{categoryname}</h1>}
          <FAQBOX
            key={id}
            question={question}
            answer={answer}
            i={id}
            expanded={expanded}
            setExpanded={setExpanded}
          />
          </>
          )
})}
        </div>
            </div>
            <div className="col-md-5 desktopview">
            <div id="faqimageright" className="mt-1">
                 <img src='/services/aboutleft.gif' alt="A visual representation of DA Tech's comprehensive services, covering custom development, website
design, mobile app development, SEO, ads management, UIUX design, and more. Explore our FAQs to
gain insights and elevate your digital journey!" title='Frequently asked questions for web, mobile and digital marketing services'/>
              </div>
            
              <div className='d-flex justify-content-center fixedcontainerfaq mx-auto'>
      <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="GET STARTED" iconbtn={getStarted}/></Link>
      <Link to="/Contact" className='mx-3'><Custobtn btnvalue="Contact" iconbtn={contact}/></Link>
      </div>
            </div>
          </div> 
        </div> 
    )
}