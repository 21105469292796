import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode,Autoplay } from 'swiper/modules';
import Lottie from 'lottie-react';

const ServiceImages = ({ imageData = [],mainimage,imagealttitle,imagealtDes }) => {
  const [selectedImage, setSelectedImage] = useState({ urlimage: mainimage, altsecondimagedes: imagealtDes, altsecondimagetitle: imagealttitle });
  const [isMainImage, setIsMainImage] = useState(true);
  const location = useLocation();

  // Define paths that should use Lottie animations
  const useLottieAnimation = [
    '/services/Maintenance_and_support',
    '/services/Custom_patches',
    '/services/SEO&ASO',
    '/services/Mobile_app_design_and_development',
    '/services/Website_design_and_development',
    '/services/Social_Media_Marketing',
    '/services/Graphic_designing',
    '/services/eCommerce_management'
  ];

  const isLottiePath = useLottieAnimation.includes(location.pathname);
  const isLottieFile = (file) => typeof file === 'object' && file.v; // Check if it's a Lottie animation

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsMainImage(false);

    // Set a timer to revert to the main image after 4 seconds
    setTimeout(() => {
      setSelectedImage({ urlimage: mainimage, altsecondimagedes: imagealtDes, altsecondimagetitle: imagealttitle });
      setIsMainImage(true);
    }, 3000); // 4 seconds
  };

  return (
    <div>
      {/* Top Image Display */}
      {/* <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {isLottiePath && isLottieFile(selectedImage.urlimage) ? (
          <Lottie
            animationData={selectedImage.urlimage}
            loop={true}
            autoplay={true}
            alt={selectedImage.altsecondimagedes}
            title={selectedImage.altsecondimagetitle}
            className="mx-auto"
            style={{ width: '400px', height: '400px', objectFit: 'contain' }} 
          />
        ) : (
          selectedImage.urlimage && (
            <img
              id="sampleImage"
              src={selectedImage.urlimage}
              alt={selectedImage.altsecondimagedes}
              title={selectedImage.altsecondimagetitle}
              style={{ width: '400px', height: '300px', objectFit: 'contain' }}
            />
          )
        )}
      </div> */}
 <div className="image-containerservice">
  <div className="small-imgservice blur-container1">
    <div className="blur-effect1">
      {imageData.length > 0 ? (
        <Swiper
          direction={'vertical'}
          slidesPerView="auto"
          centeredSlides={false}
          freeMode={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          grabCursor={false}
          shortSwipes={false}
          longSwipes={false}
          modules={[Autoplay, FreeMode]}
          speed={1000}
          breakpoints={{
            320: { slidesPerView: 2, spaceBetween: 5 },
            480: { slidesPerView: 3, spaceBetween: 10 },
            640: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {imageData.slice(0, Math.floor(imageData.length / 2)).map((image, index) => (
            <SwiperSlide key={index}  onClick={() => handleImageClick(image)} className="SwiperSlidewrapperservie">
              {isLottiePath && isLottieFile(image.urlimage) ? (
                <Lottie
                  animationData={image.urlimage}
                  loop={true}
                  autoplay={true}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  className="mx-auto image-standard-size"
                />
              ) : (
                <img
                  src={image.urlimage}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  className="image-standard-size"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No images available</p>
      )}
    </div>
  </div>

  <div className="large-imgservice">
    {isLottiePath && isLottieFile(selectedImage.urlimage) ? (
      <Lottie
        animationData={selectedImage.urlimage || mainimage}
        loop={true}
        autoplay={true}
        alt={selectedImage.altsecondimagedes}
        title={selectedImage.altsecondimagetitle}
        className="mx-auto image-standard-size"
      />
    ) : (
      selectedImage.urlimage && (
        <img
          id="sampleImage"
          src={selectedImage.urlimage}
          alt={selectedImage.altsecondimagedes}
          title={selectedImage.altsecondimagetitle}
          className="image-standard-size"
        />
      )
    )}
  </div>

  <div className="small-imgservice blur-container1">
    <div className="blur-effect1">
      {imageData.length > 0 ? (
        <Swiper
          direction={'vertical'}
          slidesPerView="auto"
          centeredSlides={false}
          freeMode={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            reverseDirection: true,
          }}
          loop={true}
          grabCursor={false}
          shortSwipes={false}
          longSwipes={false}
          modules={[Autoplay, FreeMode]}
          speed={5000}
          breakpoints={{
            320: { slidesPerView: 2, spaceBetween: 5 },
            480: { slidesPerView: 3, spaceBetween: 10 },
            640: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {imageData.slice(Math.floor(imageData.length / 2), imageData.length).map((image, index) => (
            <SwiperSlide key={index}  onClick={() => handleImageClick(image)} className="SwiperSlidewrapperservie">
              {isLottiePath && isLottieFile(image.urlimage) ? (
                <Lottie
                  animationData={image.urlimage}
                  loop={true}
                  autoplay={true}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  className="mx-auto image-standard-size"
                />
              ) : (
                <img
                  src={image.urlimage}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  className="image-standard-size"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No images available</p>
      )}
    </div>
  </div>
</div>



      {/* Bottom Image Slider */}
      {/* {imageData.length > 0 ? (
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          onSlideChange={() => {}}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {imageData.map((image, index) => (
            <SwiperSlide key={index} onClick={() => setSelectedImage(image)}>
              {isLottiePath && isLottieFile(image.urlimage) ? (
                <Lottie
                  animationData={image.urlimage}
                  loop={true}
                  autoplay={true}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  className="mx-auto"
                  style={{ width: '100%', height: '100px', cursor: 'pointer' }}
                />
              ) : (
                <img
                  src={image.urlimage}
                  alt={image.altsecondimagedes}
                  title={image.altsecondimagetitle}
                  style={{ width: '100%', height: '100px', cursor: 'pointer' }}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No images available</p>
      )} */}
    </div>
  );
};

export default ServiceImages;

