import { useState } from "react"
import axios from 'axios';

export const PortfolioForm = () => {
    const [category, setCategory] = useState();
    const [main_image, setMain_image] = useState('');
    const [main_alt, setMain_alt] = useState('');
    const [main_des, setMain_des] = useState('');
    const [category_name, setCategory_name] = useState('');
    const [extra_image, setExtra_image] = useState('');
    const [extra_alt, setExtra_alt] = useState('');
    const [extra_des, setExtra_des] = useState('');

    const ProductInsert = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(
                'http://localhost:5000/api/portfolio/InsertProduct',
                { category,main_image,main_alt,main_des,category_name,extra_image,extra_alt,extra_des}, // Pass category directly as the data object
                {
                    headers: {
                        'Accept': 'application/json', // Set the Accept header
                    },
                }
            );
            console.log('res of form', res);
        } catch (err) {
            console.log('err', err);
        }
    };

    return (
        <form onSubmit={(e) => ProductInsert(e)} style={{ marginTop: '5rem' }}>
            <input type="text" name="category" value={category} onChange={(e) => setCategory(e.target.value)} placeholder="category"/>
            <input type="file" name="main_image" value={main_image} onChange={(e) => setMain_image(e.target.value)} placeholder="main_image"/>
            <input type="text" name="main_alt" value={main_alt} onChange={(e) => setMain_alt(e.target.value)} placeholder="main_alt"/>
            <input type="text" name="main_des" value={main_des} onChange={(e) => setMain_des(e.target.value)} placeholder="main_des"/>
            <input type="text" name="category_name" value={category_name} onChange={(e) => setCategory_name(e.target.value)} placeholder="category_name"/>
            <input type="file" name="extra_image" value={extra_image} onChange={(e) => setExtra_image(e.target.value)} placeholder="extra_image"/>
            <input type="text" name="extra_alt" value={extra_alt} onChange={(e) => setExtra_alt(e.target.value)} placeholder="extra_alt"/>
            <input type="text" name="extra_des" value={extra_des} onChange={(e) => setExtra_des(e.target.value)} placeholder="extra_des"/>
            <button type="submit">Submit</button>
        </form>
    );
};