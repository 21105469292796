import companymanagement from '../../Component/FILEJSON/applications/company management system.json'
import ecommerce from '../../Component/FILEJSON/applications/ecommerce.json'
import livechat from '../../Component/FILEJSON/applications/livechat.json'
import pos from '../../Component/FILEJSON/applications/POS.json'
import marketplace from '../../Component/FILEJSON/applications/marketplace.json'
import recruiting from '../../Component/FILEJSON/applications/recruiting portal.json'
import screenshare from '../../Component/FILEJSON/applications/screenshare.json';
import servicebooking from '../../Component/FILEJSON/applications/service booking.json';
import websitemanagementsystem from '../../Component/FILEJSON/applications/website management system.json'

const SolutionData=[{
id:'01',
MetaTitle:"eCommerce System | eCommerce Marketplace | eCommerce Management System | Custom eCommerce Website | Custom Web and Admin Panel",
MetaDescription:"Our eCommerce System is a powerful blend of Bootstrap and Laravel, designed to fulfil your eCommerce management system requirements with custom web and admin panel. Get started with our eCommerce solution for your online shop.",
AltTitle:'Dynamic eCommerce solution by DA Tech',
AltDescription:"Discover our cutting-edge eCommerce Solution, expertly developed with Bootstrap and Laravel, designed to deliver a seamless online shopping experience.",
Keywords:'ecommerce system, ecommerce management system, ecommerce solution, custom web and admin panel, online shop, eCommerce Marketplace',
title:'eCommerce System | Fulfil your online shop',
slug:"eCommerce_System",
Rightimage:ecommerce,
Linklaunch:'https://hrm.thedatech.com/Product:%20eCommerce%20System',
para:`<p>Our eCommerce System is a versatility of Bootstrap meets the strength of Laravel to create a dynamic
web app for your online shop. This solution comes with a custom web and admin panel which is not
more than just a shopping cart - it's a full featured eCommerce management system designed to boost
your online shop sales. It can be used as a single eCommerce store or eCommerce Marketplace. With
secure payment integrations, streamlined inventory management and customizable features, your online
shop will stand out in the competitive eCommerce landscape.</p>
<p>Whether you're launching a new online business or enhancing an existing one, our eCommerce System is
built for success. Experience an intuitive user interface, responsive design, and powerful functionalities
that simplify the management of your online shop.</p>
<p><b>Ready to start your online shop? Get Started today and transform your eCommerce business.</b></p>`
},
{
    id:'02',
    MetaTitle:"Service Booking System | Service Scheduling System | Custom Website and Admin Panel",
    MetaDescription:"Our Service Booking System is built on the powerful combination of Bootstrap and Laravel which comes with custom website and admin panel. Start your business with the best service scheduling system.",
    AltTitle:"Efficient Service Booking with DA Tech's System",
    AltDescription:"Optimize your appointment and booking processes with DA Tech's Service Booking System, built on Bootstrap and Laravel for efficiency and passive income generation.",
    Keywords:'service booking system, service scheduling system, custom website and admin panel, passive income',
    title:'Service Booking System | Passive Income',
    slug:"Service_Booking_System",
    Rightimage:servicebooking,
    Linklaunch:'https://hrm.thedatech.com/Product:%20Service%20Booking%20System',
    para:`<p>Our Service Booking System is your go-to solution for effortless onsite and digital services management
and passive income opportunities. Crafted with Bootstrap and Laravel, our system simplifies scheduling
processes, making it an ideal choice for salons, clinics, and service-based businesses looking to
streamline operations and generate passive income.
</p>
<p>Our solution goes beyond basic scheduling - it's a comprehensive platform designed to enhance the
overall customer experience while offering opportunities for passive income through automated
bookings and client management. With easy scheduling, client reminders, and customization options, DA
Tech's Service Booking System ensures that managing appointments is a smooth and efficient process.</p><p><b>Ready to simplify your appointment management and explore passive income potential? Click below to
review the app and revolutionize your scheduling processes.</b></p>`
    },
{
        id:'03',
        MetaTitle:"Company Management System | HRM System | Manage Clients, Employees and Staf",
        MetaDescription:"Take control of your company's internal processes with DA Tech's Company Management System, built on the reliable combination of Bootstrap and Laravel. Enhance productivity, streamline communication, and optimize business operations globally with our tailored solution.",
        AltTitle:'Company Management with HRM System',
        AltDescription:"Simplify your company's internal processes with DA Tech's Company Management System, featuring Bootstrap and Laravel for efficient to manage clients, employees and staff.",
        Keywords:'company management system, HRM system, manage clients, employees and staf',
        title:'Company Management System | Manage Clients, Employees and Staf',
        slug:"Company_Management_System",
        Rightimage:companymanagement,
        Linklaunch:'https://hrm.thedatech.com/Product:%20Company%20Management%20System',
        para:`<p>At DA Tech, we recognize the critical need for efficient business operations. That’s why we’ve developed
our Company Management System, a comprehensive solution built with Bootstrap and Laravel. This
system is designed to streamline your company's internal processes, offering robust features for
managing clients, employees, and staff. From employee management to project tracking and seamless
communication, our system ensures your business runs smoothly.</p><p>Whether you're a startup focused on enhancing collaboration or an established business seeking to
boost productivity, our Company Management System is tailored to meet your needs.</p><p><b>Get started today and take pride to manage Clients, Employees and Staff from your HRM System.</b></p>`
        },
        {
            id:'04',
            MetaTitle:"Website Management System | Website Admin Panel | Custom Website Backend",
            MetaDescription:"Our Website Management System for your custom website, featuring the perfect blend of Laravel. Control your website effortlessly with user friendly content management system",
            AltTitle:"Effortless Website Management with DA Tech",
            AltDescription:"Take control of your online presence with DA Tech's Website Management System, seamlessly blending Bootstrap and Laravel for a user-friendly content management experience.",
            Keywords:'Website management system, website admin panel, website backend, custom website backend.',
            title:'Website Management System | Empowering Your Custom Website',
            slug:"Website_Management_System",
            Rightimage:websitemanagementsystem,
            Linklaunch:'https://hrm.thedatech.com/Product:%20Website%20Management%20System',
            para:`<p>Our Website Management System is a powerful backend solution crafted with Bootstrap and Laravel,
designed to give you full control over your custom website. Whether you're a blogger, business owner, or
content creator, our system provides everything you need for efficient website management. From
managing blogs, FAQs, and inquiries to handling page content, job postings, and more, our platform is
built to streamline your website operations.
</p><p>With features like intuitive content management, SEO optimization tools, and a user-friendly interface,
DA Tech’s Website Management System ensures seamless control over every aspect of your website.</p><p><b>Ready to manage your custom website effortlessly? Get started today and seamlessly oversee all aspects
of your custom website.</b></p>`
            },
        {
                id:'05',
                MetaTitle:"POS System | Products Inventory Management System | Ready-to-use POS System",
                MetaDescription:"Our POS System optimize online sales operations, leveraging the strengths of Bootstrap and Laravel. Streamline transactions and manage products inventory in Ready-to-use POS System",
                AltTitle:"POS System by DA Tech",
                AltDescription:"Enhance your point-of-sale operations with DA Tech's POS System, designed with Bootstrap and Laravel for efficient products inventory management.",
                Keywords:'POS system, products inventory management system, ready-to-use POS System',
                title:"POS System | Inventory Management System",
                slug:"POS_System",
                Rightimage:pos,
                Linklaunch:'https://hrm.thedatech.com/Product:%20POS%20System',
                para:`<p>Our POS System optimize product sales operations, designed for hassle-free transactions and efficient
products inventory management. Our ready-to-use POS System is a robust solution built with Bootstrap
and Laravel, perfect for businesses like retail stores, restaurants, or any enterprise that handles
transactions.</p><p>With features tailored for efficient transaction processing, seamless inventory management, and
enhanced customer experiences, our POS System eliminates the need for manual processes, offering you
a streamlined and effective point-of-sale solution.
</p><p><b></b></p>`
                },{
                    id:'06',
                    MetaTitle:"Recruiting System | Job Portal | Recruiting Portal | HR Recruiting Portal | Hiring Solution",
                    MetaDescription:"Our recruiting system is designed using Bootstrap and Laravel for efficiency and effectiveness. Effective companies and candidate management, job postings and hiring solution globally with HR Recruiting Portal.",
                    AltTitle:"Recruiting System",
                    AltDescription:"Simplify your hiring processes with DA Tech's Recruiting Portal | Job Portal | HR Recruiting Portal which is a best hiring solution, built using Bootstrap and Laravel for effective companies and candidate management.",
                    Keywords:'recruiting system, job portal recruiting portal, HR recruiting portal, companies and candidate management, job postings, hiring solution.',
                    title:'Streamline Hiring Processes with Our Recruiting Portal',
                    slug:"Recruiting_Portal",
                    Rightimage:recruiting,
                    Linklaunch:'https://hrm.thedatech.com/Product:%20Recruiting%20Portal',
                    para:`<p>At DA Tech, we recognize the complexities of recruitment, which is why we've developed our Recruiting
Portal—a comprehensive system built with Bootstrap and Laravel. Whether you're managing a small
business or a large enterprise, our Recruiting Portal simplifies candidate management, job postings, and
hiring processes, offering you an efficient and effective recruitment experience.
</p><p>Our Recruiting Portal is designed to enhance your hiring process globally, ensuring that you find the right
talent quickly and effectively. From managing job postings to tracking candidate progress, our system is
tailored to meet your recruitment needs.</p><p><b>Ready to transform an HR Agency? Get Started now and simplify your passive earnings.</b></p>`
                    },
                    {
                        id:'07',
                        MetaTitle:"eCommerce Marketplace Solution | B2B Marketplace | Online Store",
                        MetaDescription:"Manage your online store with our eCommerce Marketplace Solution, combining the power of Bootstrap and Laravel. Customize online store to meet your unique marketplace needs, manage vendors, and ensure a seamless online shopping experience for users.",
                        AltTitle:"eCommerce Marketplace Solution by DA Tech",
                        AltDescription:"Effortlessly create and manage your online marketplace with our eCommerce Marketplace Solution, leveraging Bootstrap and Laravel for a powerful platform.",
                        Keywords:'ecommerce marketplace solution, b2b marketplace, online store, online marketplace',
                        title:"Launch Your Online Marketplace with DA Tech's Solution",
                        slug:"Marketplace",
                        Rightimage:marketplace,
                        Linklaunch:'https://hrm.thedatech.com/Product:%20Marketplace',
                        para:`<p>eCommerce Marketplace Solution is designed to help you create and manage your online store with
ease. Built with the robust combination of Bootstrap and Laravel, our solution provides everything you
need to develop a customizable marketplace, manage vendors efficiently, and offer a seamless shopping
experience to your users. Whether you are starting a new marketplace or seeking to enhance an existing
one, DA Tech's eCommerce Marketplace Solution is crafted for success.</p><p>Our B2B Marketplace supports robust reporting capabilities, providing you with insightful data to make
informed decisions. With integrated newsletter functionalities, you can keep your users updated with the
latest products and promotions. Our solution accommodates various payment methods, offering
flexibility for your customers, while the product management module ensures that you can easily add,
update, or remove products.</p><p><b>Ready to launch your online store or B2B marketplace? Get started today and transform your
eCommerce vision into reality.</b></p>`
                        },
                    {
                            id:'08',
                            MetaTitle:"Website Live Chat Application | Live Chat Web App | Live Chat System | Video and Screen Share Solution",
                            MetaDescription:"Website Live Chat Application helps engage with your traffic in real-time with audio and video calling and screen share.",
                            AltTitle:"website live chat application",
                            AltDescription:"Integrate live chat system into your website and engage with your traffic via live chat, audio call, video and screen sharing powered by React.js and Livewire Laravel for dynamic communication.",
                            Keywords:"website live chat application, video and screen share solution, live chat web app | live chat system",
                            title:"Website Live Chat Application | Video and Screen Share Solution",
                            slug:"Live_Chat,_Video_and_Calling_Web_App",
                            Rightimage:livechat,
                            Linklaunch:'https://hrm.thedatech.com/Product:%20Live%20Chat,%20Video%20and%20Calling%20Web%20App',
                            para:`<p>Experience the Live Chat, Video and Screen Sharing with your website’s traffic which is never before. Our
dynamic solution, powered by React.js and Livewire Laravel, enables seamless communication, fostering
connections and collaboration globally.</p><p>With our live chat system, you can interact with your website visitors instantly, enhancing customer
support and increasing engagement. The video calling feature allows face-to-face interaction, building
stronger relationships with clients or team members. Our screen share functionality is perfect for
presentations, remote troubleshooting, or guiding customers through complex processes in real-time</p><p>Whether you're looking to boost customer support, connect with remote teams, or engage your
audience, DA Tech's Live Chat, Video, and Calling Web App is designed to meet your needs and drive
more sales.</p><p><b>Ready to connect seamlessly? Get Started below and transform your website’s engagement, driving
traffic and generating more sales.</b></p>`
                            },
                            {
                                id:'09',
                                MetaTitle:"Enhance Collaboration with DA Tech's Screen Sharing and Audio Video Streaming Solution",
                                MetaDescription:"Facilitate seamless collaboration with DA Tech's Screen Sharing, Live Chat, and Audio Video Streaming Solution. Built with Node.js and Angular.js, our cutting-edge solution empowers global teams with interactive and efficient communication.",
                                AltTitle:"Enhance Collaboration with DA Tech's Solution",
                                AltDescription:"Facilitate seamless collaboration with DA Tech's Screen Sharing, Live Chat, and Audio Video Streaming Solution, built with Node.js and Angular.js for cutting-edge communication.",
                                Keywords:'Screen Sharing Solution, Audio Video Streaming, Node.js, Angular.js, Collaboration Enhancement, Global Communication Tool.',
                                title:"Empower Collaboration with DA Tech's Screen Sharing and Audio Video Streaming Solution",
                                slug:"Screen_Sharing_with_Live_Chat_and_Audio",
                                Rightimage:screenshare,
                                Linklaunch:'https://hrm.thedatech.com/Screen%20Sharing%20with%20Live%20Chat%20and%20Audio%20Video%20Streaming',
                                para:`<p>At DA Tech, we understand the importance of collaborative communication, and that's why we present our Screen Sharing, Live Chat, and Audio Video Streaming Solution. Built with the cutting-edge technologies of Node.js and Angular.js, our solution empowers global teams with interactive and efficient communication.</p><p>Whether you're conducting remote meetings, facilitating training sessions, or enhancing teamwork, DA Tech's Screen Sharing and Audio Video Streaming Solution is your ideal companion. Ready to empower collaboration? Click below to review the app and transform your communication experience.</p>`
                                }]
export default SolutionData;