import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './Component/Navbar/Navbar';
import TopNavbar from './Component/Navbar/TopNavbar';
import BottomNav from './Component/Navbar/BottomNav';


const Main = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []);

  return (
    <main className='main'>
      <video 
        ref={videoRef} 
        className='backvideo' 
        src="/backgroundvideo.mp4" 
        alt="backgroundvideo" 
        autoPlay 
        muted 
        loop={true} 
        playsInline 
      />
      <div className='Navbarmaincontainer'>
        <Navbar/>
      </div>
      <div className="main-container">
        <App/>
      </div>
      <div className='footer-container'>
        <TopNavbar/>
      </div>
      <BottomNav/>
    </main>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
