import Earth  from "../../Component/EarthCom/Earth1";
import {Helmet} from "react-helmet";

function Home() {
    return(
      <>
      <Helmet>
        <title>Top-Rated IT Company | Website Design, Apps Development & Digital Marketing Agency Near Me</title>
        <meta name="description" content="Top-Rated IT Company | Digital Marketing Agency - We will execute your idea into reality, from design to
marketing. USA - CA - EU - UAE - PK - AU - NZ" />
        <meta name="keywords" content="website design, apps development, top-rated IT company, digital marketing agency near me, nearest IT
company, United States, Canada, Europe, United Arab Emirates, Pakistan, Australia, New Zealand"/>
     </Helmet>
      <Earth/>
      </>
    )
    }
    
    export default Home;