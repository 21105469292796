import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';
import Lottie from 'lottie-react';
import blogs from '../../Component/FILEJSON/other pages and includes/blogs.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import { useEffect, useState } from 'react';
import axios from 'axios';

function Blogs() {
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [catname,setCatname]=useState([]);
  const [Blogs,setBlogs]=useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
console.log('filteredBlogs',filteredBlogs.length)



      const BootstrapTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} arrow classes={{ popper: className }} />
          ))(({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
              color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: theme.palette.common.black,
            },
          }));
     
    const getBlogs=async()=>{
      try{
        const response=await axios.get('https://hrm.thedatech.com/api/blogs')
        setBlogs(response.data)
      }catch(err){
        console.log(err);
      }
    }
    const getCategory=async()=>{
      try{
        const response=await axios.get('https://hrm.thedatech.com/api/categories')
        setCatname(response.data)
      }catch(err){
        console.log(err);
      }
    }

  

    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
    };
    
    useEffect(()=>{
      getBlogs()
      getCategory()
    },[])
    useEffect(() => {
      const newFilteredBlogs = selectedCategory === 'All'
        ? Blogs
        : Blogs.filter(blog => blog.category_names.includes(selectedCategory));
      setFilteredBlogs(newFilteredBlogs);
      console.log('newFilteredBlogs:', newFilteredBlogs);
    }, [Blogs, selectedCategory]);

    return(
      <div className='servicemargin'>
  <div className='row mx-2'>
  <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-md-10 col-11 d-flex justify-content-md-center justify-content-end" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><Lottie
      animationData={blogs}
      loop={isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 30, height: 30 }}
      className='mt-2'
    /><h1 className="subpagestitle mb-0 mt-2 mx-1">BLOGS</h1></div>
            </div>
            <div className="btnsergridcontainer">
              <div className="btncatgrid" style={{width:'100%'}}>
              <button className='blogcategorybtn' onClick={() => handleCategoryClick('All')}>All</button>

                {catname && catname.map(({id,name})=>{
                  return(
                    <button className='blogcategorybtn' key={id} onClick={() => handleCategoryClick(name)}>{name}</button>
                  )
                })}
             </div>
          </div>
          <div className='blogcontainer'>
            {filteredBlogs?.map(({id,image,img_alt_dis,img_alt_text,title,blog_dis})=>{
              return(
                <div className='blogcard' key={id}>
                <div className='blogcardimg'>
                  <img src={`https://hrm.thedatech.com${image}`} alt={img_alt_dis} title={img_alt_text} style={{objectFit:'fill'}}/>
                </div>
                <Link to={`/Blogs/${title}`} style={{color:'#fff'}}><h4 className='mt-2'>{title}</h4></Link>
              </div>
              )
            })}
          </div>
      </div>
    )
    }
    
    export default Blogs;

    /*      <div
            dangerouslySetInnerHTML={{
              __html: blog_dis
                .split('</p>')[0] // Get the content before the first closing </p> tag
                .replace(/<p>/g, '') // Remove <p> tags
                .trim() // Trim any leading or trailing spaces
            }}
          /> */