import Lottie from 'lottie-react';
import registration from '../../Component/FILEJSON/how to get start/registration.json';
import scoping from '../../Component/FILEJSON/how to get start/scoping.json';
import pricing from '../../Component/FILEJSON/how to get start/pricing.json';
import live from '../../Component/FILEJSON/how to get start/live.json';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode,Autoplay } from 'swiper/modules';


export const HowtogetStartSD=()=>{
    const [isHover,setisHover]=useState(false);
    const [isHover1,setisHover1]=useState(false);
    const [isHover2,setisHover2]=useState(false);
    const [isHover3,setisHover3]=useState(false);
return(
    <div className="col-12 text-center">
    <h1 className='text-center fs-2 mt-4 largetit' style={{lineHeight:'14px'}}>HOW TO GET START</h1>
      <p className='text-center mb-2 parase'>Get your projects streamlined, receive daily updates and more features in our HRM System.</p>
      <div className='row mx-auto'>
      <Swiper
        slidesPerView={2}
        spaceBetween={9}
        freeMode={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[FreeMode,Autoplay]}
        className="mySwiper px-2"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768:{
            slidesPerView: 2,
            spaceBetween: 10,
          }
        }}
      >
        <SwiperSlide> 
          <div className='col-3'>
        <div className="Servicecard1 startSD" onMouseEnter={()=>setisHover(true)}
  onMouseLeave={()=>setisHover(false)}>
          <div className='Servicecard1front'>
          <div className='row mt-1 mx-auto'>
            <div className='col-12 justify-content-center d-flex Servicecard1fronticon'> <Lottie
  animationData={registration}
  loop={isHover}
  isStopped={!isHover}
  autoplay={isHover}
  className='mt-2'
/><h5 className='mt-2'>Registration</h5></div>
          </div>
          </div>
          <div className='Servicecard1back'>
<p>Simply register yourself with the basic details and quick start by adding your project with the requirements in  HRM System.</p>
          </div>
      </div>
        </div></SwiperSlide>
        <SwiperSlide> 
          <div className='col-3'>
        <div className="Servicecard1 startSD" onMouseEnter={()=>setisHover1(true)}
  onMouseLeave={()=>setisHover1(false)}>
        <div className='Servicecard1front'>
          <div className='row mt-1 mx-auto'>
            <div className='col-12 justify-content-center d-flex Servicecard1fronticon'><Lottie
  animationData={scoping}
  loop={isHover1}
  isStopped={!isHover1}
  autoplay={isHover1}
  className='mt-2'
/><h5 className='mt-2'>Project Scoping</h5></div>
          </div>
          </div>
          <div className='Servicecard1back'>
      <p>We will review the requirements and the dedicated Project Manager will be aligned to work with you closely to prepare a project scope.</p>
     </div>
      </div>
        </div></SwiperSlide>
        <SwiperSlide> 
          <div className='col-3'>
        <div className="Servicecard1 startSD" onMouseEnter={()=>setisHover2(true)}
  onMouseLeave={()=>setisHover2(false)}>
      <div className='Servicecard1front'>
      <div className='row mt-1 mx-auto'>
            <div className='col-12 d-flex justify-content-center Servicecard1fronticon'><Lottie
  animationData={pricing}
  loop={isHover2}
  isStopped={!isHover2}
  autoplay={isHover2}
  className='mt-1'
/><h5 className='mt-2'>Pricing</h5></div>
          </div>
      </div>
          <div className='Servicecard1back'>
          <p>Once the details are all clear, we will put together a breakdown and propose you a quotation along with the timelines.</p>
          </div>
      </div>
        </div></SwiperSlide>
        <SwiperSlide>
        <div className='col-3'>
        <div className="Servicecard1 startSD"onMouseEnter={()=>setisHover3(true)}
  onMouseLeave={()=>setisHover3(false)}>
       <div className='Servicecard1front'>
       <div className='row mt-1 mx-auto'>
            <div className='col-12 d-flex justify-content-center Servicecard1fronticon'><Lottie
  animationData={live}
  loop={isHover3}
  isStopped={!isHover3}
  autoplay={isHover3}
  className='mt-2'
/><h5 className='mt-2'>Productivity</h5></div>
          </div>
       </div>
          <div className='Servicecard1back'>
      <p>Once you approved, we will begin the work. You will be able to review the progress, daily updates and coordinate with the team in HRM.</p>
      </div>
      </div>
        </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
)
}