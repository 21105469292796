import { Link, useLocation, useParams } from "react-router-dom"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from "react";
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import Lottie from 'lottie-react';
import {Helmet} from "react-helmet";
import SolutionData from './SolutionData';
import { GetStartedbtn } from "../Application/Getstartedbtn";
import { Custobtn } from "../Application/Custobtn";
import getStarted from '../../Component/FILEJSON/other pages and includes/Getstarted.json';
import SolutionMenu from "./SolutionMenu";

export const SolutionDetail=()=>{
    const [isback,setisback]=useState(false);
    const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
    const [hoverStateImage, setHoverStateImage] = useState(false);
    const [initialAnimationPlayed, setInitialAnimationPlayed] = useState(false);

  
    const handleMouseEnterImage = () => {
      setHoverStateImage(true);
    };
    const handleMouseLeaveImage = () => {
      setHoverStateImage(false);
    };

    const handleMouseEnter1 = (id) => {
      setHoverStatesmenu(id)
    };
  
    const handleMouseLeave1 = () => {
      setHoverStatesmenu(null);
    };

    const {name}=useParams();
    



    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
      }));

      
    useEffect(() => {
      // Trigger initial animation play on page load
      if (!initialAnimationPlayed) {
        setInitialAnimationPlayed(true);
        const timer = setTimeout(() => {
          setInitialAnimationPlayed(false); // Reset state after initial play
        }, 1000); // Adjust the timeout to match the animation duration
        return () => clearTimeout(timer);
      }
    }, [initialAnimationPlayed]);

    return(
        <div className="container-fluid servicemargin">
      <div className="btnsergridcontainer">
          <BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/Solutions'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip>
         <div className="btnsergrid" id="sol_detail_mob_hide">
              {SolutionMenu.map((menu)=>{
             return(
              <BootstrapTooltip title={menu.name} onMouseEnter={() => handleMouseEnter1(menu.id)}
              onMouseLeave={() => handleMouseLeave1(menu.id)} key={menu.id}>
                      <Link to={menu.link} style={{color:'#ff6600'}}>
                      <Lottie
              animationData={menu.animationData}
              loop={!hoverStatesmenu===menu.id}
              isStopped={!hoverStatesmenu!==menu.id}
              autoplay={hoverStatesmenu===menu.id}
              style={{ width: 30, height: 30}}
            /></Link></BootstrapTooltip>
             )
            })}
             </div>
          </div>
          {SolutionData && SolutionData.map(({id,MetaTitle,MetaDescription,Keywords,slug,AltTitle,AltDescription,Rightimage,title,para})=>{
            return(
                <div key={id}>{name===slug &&         <div className="row mt-2">
                <Helmet>
     <title>{MetaTitle}</title>
     <meta name="description" content={MetaDescription} />
     <meta name="keywords" content={Keywords}/>
  </Helmet>

  <div className="col-md-6">
                <div className='faqboxcontainer21 mb-3 mt-2'>
                 <h1 className="subpagestitle">{title}</h1>
                <div
                className="mx-3"
         dangerouslySetInnerHTML={{__html: para}}
      />
      </div>
<div className="btnsergridcontainer">
         <div className="btnsergrid" id="sol_detail_mob_visible">
              {SolutionMenu.map((menu)=>{
             return(
              <BootstrapTooltip title={menu.name} onMouseEnter={() => handleMouseEnter1(menu.id)}
              onMouseLeave={() => handleMouseLeave1(menu.id)} key={menu.id}>
                      <Link to={menu.link} style={{color:'#ff6600'}}>
                      <Lottie
              animationData={menu.animationData}
              loop={!hoverStatesmenu===menu.id}
              isStopped={!hoverStatesmenu!==menu.id}
              autoplay={hoverStatesmenu===menu.id}
              style={{ width: 30, height: 30}}
            /></Link></BootstrapTooltip>
             )
            })}
             </div>
          </div>
      </div>
      <div className='col-md-6 desktopview' onMouseEnter={handleMouseEnterImage}
      onMouseLeave={handleMouseLeaveImage}>
      <Lottie
   animationData={Rightimage}
   style={{ width:'100%', height:'74%'}}
   title={AltTitle}
   alt={AltDescription}
   loop={hoverStateImage} // Loop when hovered
   isStopped={!hoverStateImage} // Stop when not hovered
   autoplay={initialAnimationPlayed} // Autoplay initially
 />    
      </div>
      <div className="d-flex flex-wrap fixedcontainer1 mx-4 justify-content-center" style={{width:"100%"}}>
<Link to="https://hrm.thedatech.com/client-signup" target='_blank' className="mx-md-2"><GetStartedbtn btnvalue="Get Started" iconbtn={getStarted}/></Link>

</div>
  </div>}
           
                </div>
            )
          })}
        </div>

    )
}