import { useEffect, useMemo, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as satellite from 'satellite.js';
import * as THREE from 'three';
import { Link } from 'react-router-dom';
import { FaBattleNet } from 'react-icons/fa6';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import "./contact.css";
import Lottie from 'lottie-react';
import contact from '../../Component/FILEJSON/other pages and includes/Contactpage.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import {Helmet} from "react-helmet";
import { GetStartedbtn } from '../Application/Getstartedbtn';
import { Custobtn } from '../Application/Custobtn';
import services from '../../Component/FILEJSON/services/services.json';
import getStarted from '../../Component/FILEJSON/other pages and includes/Getstarted.json';
import customer from '../../Component/FILEJSON/services/custom.json'
import website from '../../Component/FILEJSON/services/website.json'
import mobileapp from '../../Component/FILEJSON/services/mobileapp.json'
import ebook from '../../Component/FILEJSON/services/ebook.json'
import googlelinkedin from '../../Component/FILEJSON/services/google linkedin youtube ads.json'
import support from '../../Component/FILEJSON/services/livechat.json'
import socialmedia from '../../Component/FILEJSON/services/smm.json'
import uiux from '../../Component/FILEJSON/services/home design.json'
import seo from '../../Component/FILEJSON/services/home marketing.json'
import maintenance from '../../Component/FILEJSON/services/support.json'
import axios from 'axios';
import data from "../../Component/EarthCom/datasets/ne_110m_populated_places_simple.geojson";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgb(0, 0, 0)',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:'20px',
  color:"#fff"
};

const servicesmenu = [
  {id:1, name:'Services',animationData:services,link: '/services'},
  {id:2, name:'Custom Development',animationData:customer,link: '/services/Custom_development'},
  {id:3, name: 'Website Design and Development', animationData: website, link: '/services/Website_design_and_development'},
  { id:4, name: 'Mobile App Design and Development', animationData: mobileapp, link: '/services/Mobile_app_design_and_development'},
  {id:5, name: 'UIUX and Graphic Designing', animationData: uiux, link: '/services/Graphic_designing'},
  {id:6, name: 'SEO and ASO', animationData: seo, link: '/services/SEO&ASO'},
  {id:7, name: 'Google, YouTube and LinkedIn Ads', animationData: googlelinkedin, link: '/services/Google_YouTube_&_LinkedIn_Ads' },
  {id:8, name: 'Social Media Marketing', animationData: socialmedia, link: '/services/Social_Media_Marketing'},
  {id:9, name: 'Book and eBook Writing', animationData: ebook, link: '/services/Book_&_eBook_Writing'},
  {id:10, name: 'Maintenance and Support', animationData: maintenance, link: '/services/Maintenance_and_support' },
  {id:11, name: 'Live Chat Support and Sales', animationData: support, link: '/services/Sales_and_support' }
]
 // per frame 


export const Contact=()=>{

  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);
  const [time, setTime] = useState(new Date());
  const [hoverStatesmenu, setHoverStatesmenu] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [places, setPlaces] = useState([]);
  console.log('name',name,'email',email,'phone',phone,'message',message);

  const handleMouseEnter1 = (id) => {
    setHoverStatesmenu(id)
  };

  const handleMouseLeave1 = () => {
    setHoverStatesmenu(null);
  };

  const [successmodel,setSuccessmodel]=useState(false);
     
  const handleClose = () => setSuccessmodel(false);

  const Contactme=async(event)=>{
    event.preventDefault();
    try{
        const res=await axios.post('https://hrm.thedatech.com/api/contact-us',{name,email,phone,message})
        console.log('res',res)
        if(res.status===200){
          setSuccessmodel(true);
          setName("")
          setEmail("")
          setphone("")
          setmessage("")
        }
    }catch(err){
      console.log(err)
    }
  }
     



  



  useEffect(() => {
    // load data
    fetch(data)
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(({ features }) => setPlaces(features))
      .catch(error => console.error('Error loading places:', error));
  }, []);




  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));


    return(
        <div className="container-fluid">
           <Helmet>
        <title>Your Nearest IT Company for Design, Development and Digital Marketing Services Worldwide | DA Tech</title>
        <meta name="description" content="Contact your nearest top IT company for professional website design, mobile app, custom development,
maintenance and digital marketing services. USA - Canada - Europe - UAE - PK - Australia - New Zealand." />
        <meta name="keywords" content="contact top IT company, digital solutions, professional website design, custom solution, crm, digital
marketing services, mobile app"/>
     </Helmet>
            <div className="row">
                <div className="col-md-6 servicemargin">
                <div className='mx-2 mt-2 contactheadingbixflex'>
                <BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip>
              <div className="d-flex justify-content-md-center justify-content-end" onMouseEnter={() => setisHover(true)} 
      onMouseLeave={() => setisHover(currentValue => !currentValue)}><Lottie
      animationData={contact}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 40, height: 40 }}
      id="faqiconmain"
      className='mt-0 mb-1 mailiconwidth'
    /><h1 className="subpagestitle mb-0 mx-1 mt-1">Your nearest IT company!</h1></div>
            </div>
        <div className="contactscrollerpara mx-md-3">
        <p className='mt-1 mb-0 text-center'>We transform your digital vision into reality. Explore our range of services and kickstart your project
        through our user-friendly project management application</p>
        <p className='mt-1 mb-0 text-center'><b>Have Questions?</b> Fill out the form below with your inquiry, and our dedicated team will respond swiftly.</p>
                  <form className='contactcontainer mt-2 text-center' onSubmit={Contactme}>
            <div className='d-md-flex justify-content-center flex-wrap gap-md-2'>
                  <div className='col-md-4 col-12'><input name='name' className='contactinput' placeholder='Name*' value={name} onChange={(e)=>setName(e.target.value)} required/></div>
                  <div className='col-md-4 col-12'><input name='email' className='contactinput' placeholder='Email*' value={email} onChange={(e)=>setEmail(e.target.value)} required/></div>
            </div>
            <div className='d-md-flex justify-content-center' style={{gap:'1rem'}}>
                  <div className='col-md-8 col-12'><input name='phone' className='contactinput' placeholder='Mobile*' value={phone} onChange={(e)=>setphone(e.target.value)}  required/></div>
            </div>
            <div className='d-md-flex mb-0 justify-content-center'>
            <div className='col-md-8 col-12'><textarea id='contacttextarea' name='message' placeholder='Message*' value={message} onChange={(e)=>setmessage(e.target.value)} required></textarea>
            </div>
            </div>
            <button className="launchbtncus mt-2 mx-auto" type='submit'>
        <div className="launchbtnwrapper">
            <div className="gvzymi"><svg id="hhhhhheight" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="27.5" r="26.5" stroke="url(#paint0_angular)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 20 8 1 10 8"></circle><defs><radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.5 27.5) rotate(107.904) scale(34.1541)"><stop offset="0.0870822" stop-color="#000000"></stop><stop offset="0.69191" stop-color="#000000"></stop></radialGradient></defs></svg></div>
        </div>
        <div className="kRiXyW">
        <FaBattleNet />
        </div>
        <div className="cbypVx">Send</div></button>
        </form>
          <h4>Our Services</h4>
          <p className='mb-0'>Unlock the full potential of your digital presence with our comprehensive services:</p>
          <div className="btnsergridcontainer mx-0">
            <div className="btnctngrid">
            {servicesmenu.map((menu)=>{
             return(
              <BootstrapTooltip title={menu.name} onMouseEnter={() => handleMouseEnter1(menu.id)}
              onMouseLeave={() => handleMouseLeave1(menu.id)} key={menu.id}>
                      <Link to={menu.link} style={{color:'#ff6600'}}>
                      <Lottie
              animationData={menu.animationData}
              loop={!hoverStatesmenu===menu.id}
              isStopped={!hoverStatesmenu!==menu.id}
              autoplay={hoverStatesmenu===menu.id}
              id="servicemenuciricon"
            /></Link></BootstrapTooltip>
             )
            })}
             </div>
          </div>
          <h4 className="mt-2">Ready to Advance Your Business?</h4>
          <p>Sign up today and submit your project details. Our project
          managers will review your submission and get in touch to discuss the scope, timeline and pricing.</p>
          <p>At DA Tech, your success is our mission. Together, let’s create something exceptional and transform your
          digital possibilities into reality!</p>
        </div>
        <div className='d-flex justify-content-center flex-wrap mx-auto fixedcontainer1 w-md-50'>
      <Link to="https://hrm.thedatech.com/client-signup" target='_blank'><GetStartedbtn btnvalue="Get Started" iconbtn={getStarted}/></Link>
      <Link to="/services" className="mx-md-3"><Custobtn btnvalue="Services" iconbtn={services}/></Link>
        </div>
                  </div>
                <div className="col-md-6 desktopview">
                <div className="newearthbox" style={{width:"100%"}}>
                <Globe
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      backgroundImageUrl=""

      labelsData={places}
      labelLat={d => d.properties.latitude}
      labelLng={d => d.properties.longitude}
      labelText={d => d.properties.name}
      labelSize={d => Math.sqrt(d.properties.pop_max) * 4e-4}
      labelDotRadius={d => Math.sqrt(d.properties.pop_max) * 4e-4}
      labelColor={() => 'rgba(255, 165, 0, 0.75)'}
      labelResolution={2}
      backgroundColor='#00000000'
    />
        </div>
  </div>
            </div>
           
            <Modal
        open={successmodel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           <div id='sucessimagemodelbox' className='mx-auto'>
            <img src='/services/sucess.gif' alt='sucess.gif'/>
           </div>
          <h1 id="modal-modal-title" className='text-center' variant="h6" component="h2">
          Thank you!
          </h1>
          <p id="modal-modal-description" className='text-center' sx={{ mt: 2 }}>
          We will forward your enquiry to the relevant department and they will contact you soon if it is relevant.
          </p>
        </Box>
      </Modal>

        </div>
    )
}