import { Link } from 'react-router-dom';
import { Custobtn } from '../Application/Custobtn';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, FreeMode } from 'swiper/modules';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Lottie from 'lottie-react';
import about from '../../Component/FILEJSON/other pages and includes/about.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import {useState} from 'react';
import {Helmet} from "react-helmet";
import services from '../../Component/FILEJSON/services/services.json';
import testimonials from '../../Component/FILEJSON/other pages and includes/testimonials.json';
import { GetStartedbtn } from '../Application/Getstartedbtn';

function About() {
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
return(
  <div className="servicemargin">
     <Helmet>
        <title>Top IT Company for Design, Development and Digital Marketing Services Worldwide | DA Tech</title>
        <meta name="description" content="DA Tech is a global force in design, development and digital marketing. Since 2018, we've been crafting
innovative solutions for businesses across USA, Canada, UK and Europe." />
        <meta name="keywords" content="design and development Company, Digital Marketing, Website Design, ERP, CRM Services, Mobile App,
Ads Management, Top IT Company, Worldwide"/>
     </Helmet>
    <div className='row'>
    <div className="col-md-6">
    <div className='row mx-2'>
     <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-md-9 col-11 d-flex justify-content-end mt-1 aboutmcenter" style={{marginLeft:'2.5rem'}} onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><Lottie
      animationData={about}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 40, height: 40 }}
      id="faqiconmain"
      className='mt-1'
    /> <h1 className="subpagestitle mt-2 mx-1"> ABOUT</h1></div>
            </div>
      <div className='aboutcontentscrol mt-2 mx-md-3'>
        <h4 className='ml'>WHO WE ARE</h4>
        <p className='mt-2 ml mb-0'>DA Tech is a leader in global digital innovation since 2018. We offer unique designs and cutting-edge
technology along with the effective marketing strategies to clients worldwide, including United States,
Canada, Europe, United Arab Emirates, Pakistan, Australia and New Zealand. At DA Tech, we’re more
than just a company - we’re on a mission to create a more connected digital world.</p>
        <p className='mt-2 ml'>As a top IT company specializing in design, development, and digital marketing, our reach spans the
globe. We craft professional websites, ERP systems, CRM solutions, and custom mobile apps, while
executing successful Google Ads and Social Media marketing campaigns for diverse markets. From
growing industries to dynamic business environment, DA Tech can make a significant digital impact.</p>
        <h4 className='ml'>OUR GOAL</h4>
        <p className='mt-2 ml'>We are committed to exceeding expectations on an international scale. We focus on meeting our
customers’ specific needs with precision, ensuring excellence at every stage of your business journey. No
matter where you are in the world - DA Tech is dedicated to delivering successful outcomes. Your success
is our priority.</p>
        <h4 className='ml'>OUR PASSION</h4>
        <p className='mt-2 ml'>In today’s digital world, DA Tech is your trusted partner for digital business success. We understand the
importance of integrated marketing campaigns for a global audience of over 4.66 billion internet users.
Our expertise spans bespoke design, development and digital marketing services, setting the standard
for lead generation and impactful visual experiences.</p>
        <h4 className='ml'>BORN DIGITAL</h4>
        <p className='mt-2 ml'>As a digital-first agency, DA Tech lives and breathes innovation. We fully understand the unique needs of
businesses across United States, Canada, Europe, United Arab Emirates, Pakistan, Australia and New
Zealand. We’re passionate about supporting your business growth worldwide and won’t stop until you’re
completely satisfied.</p>
        <p className='ml'>Start your digital journey with DA Tech, your success has no limits!</p>
      </div>
      
    
      <div className='fixedcontainer '>
      <div className='d-flex flex-wrap justify-content-center'>
      <Link to="/Testimonials"><GetStartedbtn btnvalue="Testimonials" iconbtn={testimonials}/></Link>
      <Link to="/services" className='mx-3'><Custobtn btnvalue="Services" iconbtn={services}/></Link>
      </div>
      <div className='row mx-2'>
      <div className='col-1 mt-1'>
                    <p className='paraverti'><div className="rotate-90">&#169;&nbsp;</div>2018&nbsp; – &nbsp;2024</p>
                    </div>
                    <div className='col-11 mt-2 blur-container'>
                    <div className="blur-effect">
                    <Swiper
       slidesPerView="auto"
       centeredSlides={false}
       freeMode={true}
       autoplay={{
         delay: 1,
         disableOnInteraction: false,
         pauseOnMouseEnter: true,
       }}
       loop={true}
       grabCursor={false}
       shortSwipes={false}
       longSwipes={false}
       modules={[Autoplay, FreeMode]}
       className="mySwiper mb-2 mt-2"
       speed={5000}
       breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
      //onSlideChange={handleSlideChange}
      >
        <SwiperSlide>
          <img src="/carousel1/1.png" alt='DA Tech, an award-winning website agency in Central America, delivers cutting-edge
digital solutions for businesses.' title='Award-Winning Website Agency in Central America' className='largecimg'  style={{objectFit:'contain',width:'10vw',height:'12vh'}}/>
        </SwiperSlide>
        <SwiperSlide>
       <Link to="https://www.upwork.com/freelancers/~0121b5ac3c5d855695" target="__blank"><img src="/carousel1/2.png" alt='DA Tech, an award-winning design company in North America, offers innovative and
creative digital solutions for businesses.' title="North America's Award-Winning Design Company" className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link>
        </SwiperSlide>
        <SwiperSlide><Link to="https://www.guru.com/freelancers/da-tech/reviews" target="__blank"><img src="/carousel1/3.png"  className='largecimg' alt='DA Tech, an award-winning IT company in the Pacific region, excels in delivering topnotch digital services and solutions.' title='Award-Winning IT Company in the Pacific' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>
        <SwiperSlide><Link to="https://clutch.co/profile/da-tech" target="__blank"><img src="/carousel1/4.png" alt='DA Tech, the leading mobile app company in South America, specializes in creating
robust, user-friendly mobile applications for global markets.
' title='South America’s Premier Mobile App Company' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>
        <SwiperSlide><img src="/carousel1/5.png" alt='DA Tech, an award-winning software company in Europe, provides bespoke software
solutions tailored to meet global business needs.
' title='Europe’s Award-Winning Software Company' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel1/6.png" alt='DA Tech offers custom software development services in the Middle East, creating
tailored digital solutions for businesses with global ambitions.' title='Custom Development Services in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><Link to="https://www.goodfirms.co/company/da-tech" target="__blank"><img src="/carousel1/7.png" alt='DA Tech, an award-winning website development agency in Africa, crafts engaging and
responsive websites for businesses looking to expand globally.' title='Africa’s Award-Winning Website Development Agency' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>
        <SwiperSlide><img src="/carousel1/8.png" alt='DA Tech, an award-winning IT company in North America, provides comprehensive IT
solutions and services to businesses across the region.' title='North America’s Award-Winning IT Company' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel1/9.png" alt='DA Tech, an award-winning UIUX design agency in the Pacific, creates visually appealing
and user-friendly digital experiences.' title='Pacific Region’s Award-Winning UIUX Design Agency' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel1/10.png" alt='DA Tech, a top digital marketing company in South America, delivers effective online
marketing strategies for business growth.' title='South America’s Leading Digital Marketing Company' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><Link to="https://fiverr.com/datech" target="__blank"><img src="/carousel1/11.png" alt='DA Tech, an expert ads agency in Europe, specializes in managing and optimizing digital
ad campaigns across various platforms.' title='Europe’s Expert Ads Agency' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>
        <SwiperSlide><Link to="https://www.dmca.com/site-report/thedatech.com" target="__blank"><img src="/carousel1/12.png" alt='DA Tech provides expert LinkedIn Ads management services in the Middle East,
connecting businesses with professionals.' title='LinkedIn Ads Management in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>
        <SwiperSlide><img src="/carousel1/13.png" alt='DA Tech, a top YouTube Ads management agency in Africa, helps businesses reach a
broader audience with engaging video content.' title='YouTube Ads Management in Africa' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><Link to='https://www.bark.com/en/gb/company/da-tech/lKYJG/' target='__blank'><img src="/carousel1/imgpsh_fullsize_anim.png" alt='DA Tech, a top social media marketing agency in Central America, enhances brand
visibility and engagement across platforms.' title='Central America’s Premier Social Media Marketing Agency' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></Link></SwiperSlide>   
      </Swiper>
      <Swiper
       slidesPerView="auto"
       centeredSlides={false}
       freeMode={true}
       autoplay={{
         delay: 1,
         disableOnInteraction: false,
         pauseOnMouseEnter: true,
       }}
       loop={true}
       grabCursor={false}
       shortSwipes={false}
       longSwipes={false}
       modules={[Autoplay, FreeMode]}
       className="mySwiper"
       speed={5000}
       dir="rtl"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <img src="/carousel2/1.png" alt=' DA Tech offers professional content writing services in North America, creating engaging
and impactful content for businesses.' title='North America’s Expert Content Writing Services' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src="/carousel2/2.png" alt='DA Tech specializes in eBook and book writing services in the Pacific, turning ideas into
compelling narratives.' title='Pacific Region’s Leading eBook and Book Writing Agency' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/>
        </SwiperSlide>
        <SwiperSlide><img src="/carousel2/3.png" alt='DA Tech provides expert web maintenance services in South America, ensuring your
website runs smoothly and securely.' title='Reliable Web Maintenance Services in South America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/4.png" alt='DA Tech offers reliable app maintenance services in Europe, keeping your mobile
applications up-to-date and optimized.' title='Expert App Maintenance Services in Europe' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'11vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/5.png" alt='DA Tech provides 24/7 live chat support and sales services in the Middle East, enhancing
customer engagement and satisfaction.' title='24/7 Live Chat Support and Sales in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'11vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/6.png" alt='DA Tech is an award-winning provider of IT services in Central America, offering
innovative digital solutions.' title='Award-Winning IT Services in Central America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/7.png" alt='DA Tech, an award-winning design company in North America, delivers creative and
aesthetically pleasing digital solutions.' title="North America's Award-Winning Design Company" className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/8.png" alt='DA Tech, an award-winning IT company in the Pacific, is known for excellence in digital
services and solutions.' title='Award-Winning IT Company in the Pacific Region' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/9.png" alt='DA Tech, a premier development company in South America, creates impactful and
scalable digital solutions for businesses.' title='Top Development Company in South America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/10.png" alt='DA Tech, an award-winning software company in Europe, provides bespoke software
solutions tailored to meet business needs.' title='Award-Winning Software Company in Europe' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/11.png" alt='DA Tech specializes in custom development services in the Middle East, offering tailored
digital solutions for businesses.' title='Custom Development Services in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/12.png" alt='DA Tech is an award-winning website development agency in Africa, offering engaging
and responsive web development services.' title='Comprehensive Website Development in Africa' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/13.png" alt='DA Tech, a top mobile app development company in North America, creates robust and
user-friendly mobile applications.' title='Mobile App Development Services in North America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/14.png" alt='DA Tech, an award-winning UIUX and graphic design agency in the Pacific, delivers
visually appealing digital experiences.' title='UIUX and Graphic Design Experts in the Pacific' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/15.png" alt='DA Tech offers expert SEO and ASO services in South America, boosting online visibility
and app store rankings.' title='SEO and ASO Services in South America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/angular.png" alt='DA Tech provides strategic Google Ads management services in Europe, driving targeted
traffic and increasing online presence.' title='Google Ads Management Services in Europe' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/17.png" alt='DA Tech offers effective LinkedIn Ads management services in the Middle East,
connecting businesses with the right professionals.' title='LinkedIn Ads Management Experts in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/18.png" alt='DA Tech provides comprehensive YouTube Ads management services in Africa, helping
businesses reach a broader audience.' title='YouTube Ads Management Services in Africa' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/19.png" alt='DA Tech, a top social media marketing agency in Central America, enhances brand
visibility and engagement.' title='Social Media Marketing Experts in Central America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/20.png" alt='DA Tech offers expert content writing services in North America, creating compelling and
engaging content for businesses.' title='Professional Content Writing Services in North America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/21.png" alt='DA Tech specializes in eBook and book writing services in the Pacific, transforming ideas
into captivating narratives.' title='eBook and Book Writing Services in the Pacific' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/22.png" alt='DA Tech provides reliable web maintenance services in South America, ensuring your
website operates smoothly and securely.' title='Reliable Web Maintenance in South America' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/23.png" alt='DA Tech offers professional app maintenance services in Europe, keeping mobile
applications up-to-date and performing optimally.' title='App Maintenance Services in Europe' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/sap.png" alt=" DA Tech offers 24/7 live chat support and sales services in the Middle East, ensuring
customer engagement and satisfaction." title='Live Chat Support and Sales Services in the Middle East' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/aws.png" alt="DA Tech, an award-winning IT company in Canada, excels in delivering innovative digital
solutions for businesses." title='Award-Winning IT Company in Canada' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
        <SwiperSlide><img src="/carousel2/openAL.png" alt="DA Tech, an award-winning digital solutions provider in Australia, specializes in
innovative and customized digital services for businesses." title='Award-Winning Digital Solutions in Australia' className='largecimg' style={{objectFit:'contain',width:'10vw',height:'12vh'}}/></SwiperSlide>
      </Swiper>
      </div>
                    </div>
      </div>
                   
                  </div>
     </div>
     <div className="col-md-6 mt-5 desktopview">
     <div className='row'  id="rightimageabout" alt="Discover DA Tech’s global expertise in software development and digital marketing. Since
2018, we've provided innovative solutions to businesses across Central America, North America, the
Pacific, South America, Europe, the Middle East, and Africa. Partner with DA Tech, your trusted IT ally on
the path to success." title='Premier IT Company for Global Development and Digital Marketing - DA Tech'>
      <div className='col-6 mx-auto' id='aboutrightimm'>
      <img src='/services/aboutright.gif' alt="DA Tech is a worldwide leader in digital innovation, delivering groundbreaking solutions
to businesses since 2018. We connect clients across Africa, Central America, Europe, the Middle East,
North America, the Pacific, and South America with top-tier design and technology. At DA Tech, we’re
committed to creating a more digitally connected world." title='Driving Global Innovation Since 2018 - DA Tech'/>
      </div>
       
        </div>
     </div>
    </div>
  </div>
)
}

export default About;