import "../FAQ/faq.css";
import { FAQBOX } from "../FAQ/FAQBOX";
import { Link } from 'react-router-dom';
import { Custobtn } from "../Application/Custobtn";
import { GetStartedbtn } from "../Application/Getstartedbtn";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from "react";
import Lottie from 'lottie-react';
import privacy from '../../Component/FILEJSON/other pages and includes/privacy-policy.json'
import back from "../../Component/FILEJSON/other pages and includes/Back.json"
import getStarted from '../../Component/FILEJSON/how to get start/GetStarted.json'
import tscs from "../../Component/FILEJSON/other pages and includes/t&c's.json";
import services from '../../Component/FILEJSON/services/services.json';
import {Helmet} from "react-helmet";

const data=[{
  id:1,
  question:"We collect information when you engage with our services",
  answer:`<ul><li className='mt-3 mb-3'>Contact Information: Name, email, password, location details.</li><li className='mt-3 mb-3'>Project-related Data: Details provided during project initiation, including server access and
marketing collaterals.</li><li className='mt-3 mb-3'>Service Usage Data: Information on how you utilize our services.</li></ul>`,
}
]

const Geographicdata=[{
  id:2,
  question:"Services We Offer",
  answer:`<ul><li className='mb-3'>Explore our diverse range of services tailored to meet your digital needs:</li><li className='mb-3'>Custom Development Services</li><li className='mt-3 mb-3'>Website Design and Development Solutions</li><li className='mt-3 mb-3'>Mobile Application Design Development</li><li className='mt-3 mb-3'>UI/UX and Graphic Designing</li><li className='mt-3 mb-3'>SEO and ASO Services</li><li className='mt-3 mb-3'>Paid Ads Management</li><li className='mt-3 mb-3'>Social Media Marketing</li><li className='mt-3 mb-3'>Content Writing</li><li className='mt-3 mb-3'>Book and eBook Writing</li><li className='mt-3 mb-3'>Web and App Maintenance and Support</li><li className='mt-3 mb-3'>Live Chat Support and Sales</li></ul>`,
}
]
const Informationdata=[{
  id:3,
  question:"We utilize your information to",
  answer:`<ul><li className='mt-3 mb-3'>Provide customized and efficient services.</li><li className='mt-3 mb-3'>Ensure the security of your user experience.</li><li className='mt-3 mb-3'>Improve our platform based on usage patterns.</li></ul>`,
},
{
  id:4,
  question:"Cookies and Tracking Technologies",
  answer:"Our website employs cookies to enhance your browsing experience. You have the option to manage cookie preferences through your browser settings.",
}
]

export const Career=()=>{
  const [expanded, setExpanded] = useState(false);
  const [isHover,setisHover]=useState(false);
  const [isback,setisback]=useState(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
    return(
        <div className='servicemargin'>
           <Helmet>
        <title>Trusted International IT Company | Privacy Policy | DA Tech</title>
        <meta name="description" content="Trusted International IT Company - Your reliable ally for design, development and digital marketing
solutions and more. Explore how we prioritize your privacy and deliver tailored digital solutions globally." />
        <meta name="keywords" content="trusted international IT company, da tech, privacy policy, software company, registered IT company,
digital solutions"/>
     </Helmet>
            <div className='row mx-2'>
            <div className="col-1 mt-2"><BootstrapTooltip title="Previous Page" onMouseEnter={()=>setisback(true)}
      onMouseLeave={()=>setisback(false)}>
              <Link to='/'><Lottie
      animationData={back}
      loop={!isback}
      isStopped={!isback}
      autoplay={isback}
      style={{ width: 40, height: 40}}
      id='backbtnport'
    /></Link></BootstrapTooltip></div>
              <div className="col-11 d-flex justify-content-md-center justify-content-end" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}><Lottie
      animationData={privacy}
      loop={!isHover}
      isStopped={!isHover}
      autoplay={isHover}
      style={{ width: 30, height: 30 }}
      className='mt-2 privicon'
    /><h1 className="subpagestitle mb-0 mx-1 mt-2 mobilepara" id="eightheight">Empowering Your Digital Future!</h1></div>
            </div>
          <p className="text-center mx-5 mobilepara" style={{fontFamily:"CFNotche-Bold"}}>We value your privacy and strive to provide transparency on how we collect, use, and
protect your personal information. This Privacy Policy outlines the practices followed since our
establishment as a sole proprietorship in 2018.</p>
        <div className="faqboxcontainer1 bghb mx-auto" style={{width:"60%"}}>
          <h1 className='text-center' style={{color:'rgba(254,136,0,255)'}}>Personal Information Collection</h1>
          {data && data.map(({id,question,answer})=>{
            return(
              <FAQBOX
            key={id}
          question={question}
          answer={answer}
          i={id} expanded={expanded} setExpanded={setExpanded}
        />
            )
          })} 
        <h1 className='text-center mt-1' style={{color:'rgba(254,136,0,255)'}}>Geographic Coverage</h1>
        <p className="mt-1">DA Tech proudly serves clients across the globe, covering regions such as Africa, Central America,
        Europe, the Middle East, North America, the Pacific, and South America.</p>
        {Geographicdata && Geographicdata.map(({id,question,answer})=>{
            return(
              <FAQBOX
            key={id}
          question={question}
          answer={answer}
          i={id} expanded={expanded} setExpanded={setExpanded}
        />
            )
          })} 
         <h1 className='text-center mt-1' style={{color:'rgba(254,136,0,255)'}}>How We Use Your Information</h1>
         {Informationdata && Informationdata.map(({id,question,answer})=>{
            return(
              <FAQBOX
            key={id}
          question={question}
          answer={answer}
          i={id} expanded={expanded} setExpanded={setExpanded}
        />
            )
          })} 
           <h1 className='text-center mt-1' style={{color:'rgba(254,136,0,255)'}}>Geographic and Service Limitations</h1>
            <p className="mt-1">While we are dedicated to serving a global clientele, offering services in Africa, Central America, Europe,
            the Middle East, North America, the Pacific, and South America.</p>
           
            <h1 className='text-center mt-1' style={{color:'rgba(254,136,0,255)'}}>Contact Us</h1>
             <p className="mt-1">For any inquiries or to seek further clarification on our privacy practices, please reach out to us at
info@thedatech.com. <br/>Thank you for choosing DA Tech for your digital endeavors!</p>
            
        </div> 
        <div className='d-flex justify-content-center mt-2 fixedcontainer1' id="removermob" style={{width:'100%'}}>
        <Link to="/terms&condition" className="mx-3"><GetStartedbtn btnvalue="Terms & C’s" iconbtn={tscs}/></Link>
              <Link to="https://hrm.thedatech.com/client-signup" target='__blank'><GetStartedbtn btnvalue="Get Started" iconbtn={getStarted}/></Link>
              <Link to="/services" className="mx-3"><Custobtn btnvalue="Services" iconbtn={services}/></Link>
      
      </div>
        </div> 
    )
}