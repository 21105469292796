import Lottie from 'lottie-react';
import registration from '../../Component/FILEJSON/how to get start/registration.json';
import scoping from '../../Component/FILEJSON/how to get start/scoping.json';
import pricing from '../../Component/FILEJSON/how to get start/pricing.json';
import live from '../../Component/FILEJSON/how to get start/live.json';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode,Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

export const HowtogetStart=()=>{
  const [isHover,setisHover]=useState(false);
  const [isHover1,setisHover1]=useState(false);
  const [isHover2,setisHover2]=useState(false);
  const [isHover3,setisHover3]=useState(false);
    return(
        <div className="text-center fixedcontainer1" style={{width:'100%'}}>
        <h1 className='text-center mt-md-3 subpagestitle' style={{lineHeight:'14px'}}>HOW TO GET START</h1>
          <p className='text-center mb-2 parase' style={{fontFamily:"CFNotche-Bold"}}>Get your projects streamlined, receive daily updates and more features in our HRM System.</p>
          <div className='marginRightforhowtos justify-content-center'>
             <Swiper className="mySwiper p-md-2 p-sm-1" 
             slidesPerView={4}
        spaceBetween={5}
        freeMode={false}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={false}
        modules={[FreeMode,Autoplay]}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 5,
            freeMode: true,
            loop: true,
        },   
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          769: {
            slidesPerView: 4,
            spaceBetween: 10,
          }
        }}>
             <SwiperSlide>
             <div className="Servicecard1 mx-auto" onMouseEnter={()=>setisHover(true)}
      onMouseLeave={()=>setisHover(false)}>
              <div className='Servicecard1front'>
              <div className='row mt-md-1 mx-auto'>
                <div className='col-12 justify-content-center d-flex Servicecard1fronticon'> <Lottie
      animationData={registration}
      loop={isHover}
      isStopped={!isHover}
      autoplay={isHover}
      className='mt-2'
    /><h5 className='mt-2'>Registration</h5></div>
              </div>
              </div>
              <div className='Servicecard1back'>
<p>Simply register yourself with the basic details and quick start by adding your project with the requirements in  HRM System.</p>
              </div>
          </div>
             </SwiperSlide>
             <SwiperSlide>
             <div className="Servicecard1 mx-auto" onMouseEnter={()=>setisHover1(true)}
      onMouseLeave={()=>setisHover1(false)}>
            <div className='Servicecard1front'>
              <div className='row mt-1 mx-auto'>
                <div className='col-12 justify-content-center d-flex Servicecard1fronticon'><Lottie
      animationData={scoping}
      loop={isHover1}
      isStopped={!isHover1}
      autoplay={isHover1}
      className='mt-2'
    /><h5 className='mt-2'>Project Scoping</h5></div>
              </div>
              </div>
              <div className='Servicecard1back'>
          <p>We will review the requirements and the dedicated Project Manager will be aligned to work with you closely to prepare a project scope.</p>
         </div>
          </div>
             </SwiperSlide>
             <SwiperSlide>
             <div className="Servicecard1 mx-auto" onMouseEnter={()=>setisHover2(true)}
      onMouseLeave={()=>setisHover2(false)}>
          <div className='Servicecard1front'>
          <div className='row mt-1 mx-auto'>
                <div className='col-12 d-flex justify-content-center Servicecard1fronticon'><Lottie
      animationData={pricing}
      loop={isHover2}
      isStopped={!isHover2}
      autoplay={isHover2}
      className='mt-1'
    /><h5 className='mt-2'>Pricing</h5></div>
              </div>
          </div>
              <div className='Servicecard1back'>
              <p>Once the details are all clear, we will put together a breakdown and propose you a quotation along with the timelines.</p>
              </div>
          </div>
             </SwiperSlide>
             <SwiperSlide>
             <div className="Servicecard1 mx-auto"onMouseEnter={()=>setisHover3(true)}
      onMouseLeave={()=>setisHover3(false)}>
           <div className='Servicecard1front'>
           <div className='row mt-1 mx-auto'>
                <div className='col-12 d-flex justify-content-center Servicecard1fronticon'><Lottie
      animationData={live}
      loop={isHover3}
      isStopped={!isHover3}
      autoplay={isHover3}
      className='mt-2'
    /><h5 className='mt-2'>Productivity</h5></div>
              </div>
           </div>
              <div className='Servicecard1back'>
          <p>Once you approved, we will begin the work. You will be able to review the progress, daily updates and coordinate with the team in HRM.</p>
          </div>
          </div>
             </SwiperSlide>
            </Swiper>
          </div>
        </div>
    )
}